import {NgModule} from '@angular/core';
import {SharedModule} from "../../shared.module";
import {ImagePreviewComponent} from "./image-preview.component";
import {SliderComponent} from "../slider/slider.component";

@NgModule({
  declarations: [
    ImagePreviewComponent
  ],
  imports: [
    SharedModule,
    SliderComponent
  ],
  exports: [
    ImagePreviewComponent
  ]
})
export class ImagePreviewModule {
}
