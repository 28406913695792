import {Component, Input} from '@angular/core';
import {EnvioDeclaracaoAlfandegaModel} from "../../models/envio-declaracao-alfandega.model";

@Component({
  selector: 'app-descricao-declaracao-envio',
  templateUrl: './descricao-declaracao-envio.component.html'
})
export class DescricaoDeclaracaoEnvioComponent {

  @Input()
  declaracao: EnvioDeclaracaoAlfandegaModel;

}
