import {Directive, HostListener} from '@angular/core';
import {NgControl} from "@angular/forms";

@Directive({
  selector: '[capitalize]',
  standalone: true
})
export class CapitalizeDirective {

  constructor(
    private readonly control: NgControl
  ) {
  }

  @HostListener("change")
  onChange(): void {
    if (this.control?.control?.value) {
      let value = this.control.control.value;
      if (value) {
        this.control.control.setValue(value.toUpperCase());
      }
    }
  }
}
