import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'emptyValue'})
export class EmptyValuePipe implements PipeTransform {
  transform(value: any, ..._: any[]): any {
    if (!value && value !== 0) {
      return '–';
    }

    return value;
  }
}
