import {Component} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {faChevronUp, faEnvelopeOpenText} from "@fortawesome/free-solid-svg-icons";
import {faFacebookF, faInstagram, faTwitter, faWhatsapp, faYoutube} from "@fortawesome/free-brands-svg-icons";
import {Utils} from "../../framework/utils";
import {VERSION} from "../../../../version";

@Component({
  selector: 'app-footer-geral',
  templateUrl: './footer-geral.component.html',
  styleUrls: ['./footer-geral.component.scss']
})
export class FooterGeralComponent {

  envelopeOpenText = faEnvelopeOpenText;
  chevronUp = faChevronUp;
  whatsapp = faWhatsapp;
  facebook = faFacebookF;
  instagram = faInstagram;
  twitter = faTwitter;
  youtube = faYoutube;

  contactEmail: string;
  readonly data = new Date();

  constructor() {
    this.contactEmail = environment.contactEmail;
  }

  scrollToTop = Utils.scrollTo;
  protected readonly VERSION = VERSION + (!environment.production ? " (testing)" : "");
}
