import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {PageModel} from "../../models/page.model";
import {faChevronLeft, faChevronRight, faEllipsis} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {first} from "rxjs";

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  imports: [
    NgIf,
    TranslateModule,
    FontAwesomeModule,
    NgForOf
  ],
  standalone: true
})
export class PaginationComponent implements OnInit {

  ellipsis = faEllipsis;
  chevronLeft = faChevronLeft;
  chevronRight = faChevronRight;

  @Input()
  page = new PageModel<any>();

  @Output()
  pageChange = new EventEmitter<PageModel<any>>();

  @Output()
  load = new EventEmitter<void>();

  ngOnInit(): void {
    this.load.next();
  }

  nextPage(): void {
    if (!this.isLast()) {
      this.page.currentPage++;
      this.pageChange.next(this.page);
      this.load.next();
    }
  }

  previousPage(): void {
    if (!this.isFirst()) {
      this.page.currentPage--;
      this.pageChange.next(this.page);
      this.load.next();
    }
  }

  firstPage(): void {
    this.page.currentPage = 0;
    this.pageChange.next(this.page);
    this.load.next();
  }

  lastPage(): void {
    this.page.currentPage = this.page.totalPages - 1;
    this.pageChange.next(this.page);
    this.load.next();
  }

  isFirst(): boolean {
    return this.page.currentPage == 0;
  }

  isLast(): boolean {
    return (this.page.currentPage + 1) >= this.page.totalPages;
  }

  paginacao(pagina: number): void {
    if (pagina !== this.page.currentPage) {
      this.page.currentPage = pagina;
      this.pageChange.next(this.page);
      this.load.next();
    }
  }

  get paginas(): number[] {
    let paginas: number[] = [];
    let start = this.page.currentPage - 3;
    let end = this.page.currentPage + 4;

    if (start < 0) {
      start = 0;
      end = 7;
    }

    if (end > this.page.totalPages - 1) {
      end = this.page.totalPages;
    }

    if (end == this.page.totalPages) {
      start = end - 8;

      if (start < 0) {
        start = 0;
      }
    }

    for (let i = start; i < end; i++) {
      paginas.push(i);
    }

    return paginas;
  }

  protected readonly first = first;
}
