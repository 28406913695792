<footer class="footer-nao-autenticado">
  <div class="footer conteudo nao-autenticado">
    <div class="mapa-sistema">
      <div class="container-fluid">
        <div class="col-lg-3">
          <a routerLink="/" class="link-home" (click)="scrollToTop()"
             [title]="'system.logomarca' | translate">
            <img alt="ViajaBox" ngSrc="assets/img/logo-viajabox.webp" width="188" height="60"
                 [alt]="'system.logomarca' | translate" [title]="'system.logomarca' | translate">
          </a>
        </div>
        <div class="col-lg-2">
          <ul>
            <li>
              <a routerLink="/quem-somos" (click)="scrollToTop()" [title]="'main.menu.quem.somos' | translate">
                {{'main.menu.quem.somos' | translate}}
              </a>
            </li>
            <li>
              <a routerLink="/vantagens" (click)="scrollToTop()" [title]="'main.menu.vantagens' | translate">
                {{'main.menu.vantagens' | translate}}
              </a>
            </li>
          </ul>
        </div>
        <div class="col-lg-2">
          <ul>
            <li>
              <p class="nossos-servicos">{{'inicio.footer.servicos' | translate}}</p>
            </li>
            <li>
              <a routerLink="/redirecionamento" (click)="scrollToTop()" [title]="'main.menu.redirecionamento' | translate">
                {{'main.menu.redirecionamento' | translate}}
              </a>
            </li>
            <li>
              <a routerLink="/compra-viajabox" (click)="scrollToTop()" [title]="'main.menu.compra.viajabox' | translate">
                {{'main.menu.compra.viajabox' | translate}}
              </a>
            </li>
          </ul>
        </div>
        <div class="col-lg-2">
          <ul>
            <li>
              <a routerLink="/central-ajuda" (click)="scrollToTop()" [title]="'main.menu.ajuda.titulo' | translate">
                {{'main.menu.ajuda.titulo' | translate}}
              </a>
            </li>
            <li>
              <a routerLink="/lojas-sugeridas" (click)="scrollToTop()" [title]="'main.menu.lojas' | translate">
                {{'main.menu.lojas' | translate}}
              </a>
            </li>
          </ul>
        </div>
        <div class="col-lg-3">
          <ul class="redes-sociais">
            <li>
              <a href="https://www.facebook.com/ViajaBox-260491780961484/" target="_blank" class="btn-face"
                 title="Facebook" rel="noopener" style="width: 34px; display: inline-block; height: 25px;">
                <fa-icon [icon]="facebook"></fa-icon>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/viaja_box/" target="_blank" class="btn-instagram"
                 title="Instagram" rel="noopener" style="width: 38px; display: inline-block; height: 25px;">
                <fa-icon [icon]="instagram"></fa-icon>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/ViajaBox" target="_blank" class="btn-twitter" title="Twitter" rel="noopener"
                 style="width: 40px; display: inline-block; height: 25px;">
                <fa-icon [icon]="twitter"></fa-icon>
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCPXSI-jfj5gww3-qSaN2ztQ" target="_blank" class="btn-youtube"
                 title="Youtube" rel="noopener" style="width: 41px; display: inline-block; height: 25px;">
                <fa-icon [icon]="youtube"></fa-icon>
              </a>
            </li>
          </ul>
          <div class="contatos">
            <a href="https://api.whatsapp.com/send?l=pt_BR&phone=13215227341" target="_blank" class="btn-whatsapp"
               title="WhatsApp" rel="noopener">
              <span style="width: 15px; height: 25px; display: inline-block"><fa-icon [icon]="whatsapp"></fa-icon></span> +1 (321) 522-7341
            </a>
            <a href="mailto:{{contactEmail}}" title="E-mail" class="btn-email">
              <span class="icon" style="width: 15px; height: 25px; display: inline-block"><fa-icon [icon]="envelopeOpenText"></fa-icon></span> {{contactEmail}}
            </a>
            <div class="v">{{VERSION}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-copyright">
      <strong>Copyright Ⓒ 2017–{{data | date: 'yyyy'}} – ViajaBox</strong>&nbsp;
      <span class="direitos">{{'inicial.direitos' | translate}}</span>
      <a class="termos" routerLink="/termos-privacidade" (click)="scrollToTop()"
         [title]="'main.login.termoDeUso' | translate">
        {{'main.login.termoDeUso' | translate}}
      </a>
    </div>
  </div>
  <a href="https://api.whatsapp.com/send?l=pt_BR&phone=13215227341" target="_blank" class="btn-whatsapp-flutuante"
     title="WhatsApp" rel="noopener">
    <fa-icon [icon]="whatsapp"></fa-icon>
  </a>
  <div (click)="scrollToTop()" class="btn-topo" title="Topo">
    <fa-icon [icon]="chevronUp"></fa-icon>
  </div>
</footer>
