import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-pontuacao',
  templateUrl: './pontuacao.component.html',
  styleUrls: ['./pontuacao.component.scss']
})
export class PontuacaoComponent {

  @Input()
  modalId = 'modalPontuacao';
}
