<carousel [isAnimated]="true" [interval]="3000">
  <slide class="s">
    <div class="text text-center">
      <h1 class="laranja">{{'inicio.destaque.titulo' | translate}}</h1>
      <h2 class="azul">{{'inicio.destaque.subtitulo' | translate}}</h2>
      <h3 class="menor">{{'inicio.destaque.text' | translate}}</h3>
    </div>
    <img ngSrc="assets/img/carousel/foto-inicial.webp"
         [title]="'inicio.destaque.alt' | translate"
         [alt]="'inicio.destaque.alt' | translate" height="540" width="545">
  </slide>
  <slide class="s">
    <div class="col-md text text-center">
      <h1 class="laranja">{{'inicio.destaque.video.titulo' | translate}}</h1>
      <a class="buttons btn-video" (click)="setVideo(true)"
         [title]="'inicio.destaque.video.titulo' | translate">
        <span class="i-video">
          <fa-icon [icon]="video"></fa-icon>
        </span>
        {{'inicio.destaque.video.botao' | translate}}<br/>
        <small>{{'inicio.destaque.video.tempo' | translate}}</small>
      </a>
    </div>
    <img class="d-block w-100 video" ngSrc="assets/img/carousel/foto-video.webp"
         [title]="'inicio.destaque.video.alt' | translate"
         [alt]="'inicio.destaque.video.alt' | translate" height="540" width="545">
    <img class="videom" ngSrc="assets/img/carousel/foto-video-mobile.webp"
         [title]="'inicio.destaque.video.alt' | translate"
         [alt]="'inicio.destaque.video.alt' | translate" height="549" width="554">
  </slide>
  <slide class="s">
    <div class="col-md text text-center">
      <h1 class="laranja">{{'inicio.destaque.calculadora.titulo' | translate}}</h1>
      <a routerLink="calculadora-frete" class="buttons" [title]="'inicio.destaque.video.titulo' | translate">
        <span class="i-calc">
          <fa-icon [icon]="calculator"></fa-icon>
        </span>
        <span class="t-calc">
          {{'inicio.destaque.calculadora.botao' | translate}}
        </span>
      </a>
    </div>
    <img ngSrc="assets/img/carousel/foto-calculadora.webp"
         [alt]="'inicio.destaque.calculadora.alt' | translate"
         [title]="'inicio.destaque.calculadora.alt' | translate" height="540" width="545">
  </slide>
</carousel>

<!-- Modal video -->
<div class="modal fade" id="modalVideo" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <div class="titulo-modal">{{'inicio.destaque.video.botao' | translate}}</div>
      </div>
      <div class="modal-body text-center">
        <div itemscope itemprop="VideoObject" itemtype="https://schema.org/VideoObject">
          <meta itemprop="duration" content="PT2M40S"/>
          <meta itemprop="uploadDate" content="2017-01-21T14:00:00+00:00"/>
          <link itemprop="embedUrl" href="https://www.youtube.com/embed/bjxwkKu8uYk"/>
          <link itemprop="thumbnailUrl" href="https://img.youtube.com/vi/bjxwkKu8uYk/maxresdefault.jpg"/>
          <meta itemprop="inLanguage" content="pt">
          <meta itemprop="contentUrl" content="https://www.youtube.com/embed/bjxwkKu8uYk"/>
          <meta itemprop="name" content="Como funciona a Viajabox">
          <meta itemprop="description" content="Como funciona a Viajabox">
          <iframe
            *ngIf="showVideo"
            width="1080" height="607" src="https://www.youtube.com/embed/bjxwkKu8uYk" title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="setVideo(false)">
          {{'global.fechar' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
