import {Component, OnDestroy, OnInit} from '@angular/core';
import {DadosDominioService} from "../../../shared/services/dados-dominio.service";
import {Subscription, take} from "rxjs";
import {PaisModel} from "../../../shared/models/pais.model";
import {TaxaCobrancaModel} from "../../../shared/models/taxa-cobranca.model";
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../../../../environments/environment";
import {EnvioModel} from "../../../shared/models/envio.model";
import {
  faArrowRight,
  faClipboardList,
  faCreditCard,
  faEnvelope,
  faEnvelopeOpenText,
  faFlagUsa,
  faParachuteBox,
  faQuestionCircle,
  faShoppingCart,
  faTasks
} from "@fortawesome/free-solid-svg-icons";
import {faFacebookF, faTwitter, faWhatsapp, faYoutube} from "@fortawesome/free-brands-svg-icons";
import {LanguageService} from "../../../shared/services/language.service";
import {EnderecoUsuarioModel} from "../../../shared/models/endereco-usuario.model";
import {MessageService} from "../../../shared/services/message.service";
import {AutenticacaoService} from "../../../shared/services/autenticacao.service";

declare const $: any;

export class Medida {
  codigo: string;
  descricao: string;
}

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit, OnDestroy {

  protected readonly clipboardList = faClipboardList;
  protected readonly questionCircle = faQuestionCircle;
  protected readonly envelopeOpenText = faEnvelopeOpenText;
  protected readonly whatsapp = faWhatsapp;
  protected readonly shoppingCart = faShoppingCart;
  protected readonly tasks = faTasks;
  protected readonly parachuteBox = faParachuteBox;
  protected readonly creditCard = faCreditCard;
  protected readonly flagUsa = faFlagUsa;
  protected readonly facebook = faFacebookF;
  protected readonly twitter = faTwitter;
  protected readonly youtube = faYoutube;
  protected readonly arrowRight = faArrowRight;
  protected readonly envelope = faEnvelope;

  email: string;

  pesoLbs: number;
  priority: EnvioModel;
  priorityExpress: EnvioModel;
  first: EnvioModel;
  packet: EnvioModel;
  express: EnvioModel;

  listaPaisCompleta: PaisModel[] = [{
    idPais: 30,
    codigo: "BR",
    descricao: "Brazil",
    descricaoPT: "Brasil"
  }];
  listaPais: PaisModel[] = this.listaPaisCompleta;
  pais: PaisModel = this.listaPaisCompleta[0];

  listaTaxaCobranca: TaxaCobrancaModel[] = [];

  kg: Medida;
  lb: Medida;
  listaMedida: Medida[];
  medida: Medida;
  contactEmail: string;
  enderecoUsuario: EnderecoUsuarioModel;
  cep: string;
  lbs: number;
  lang: string;
  dimensao: boolean;
  tipoDimensao: string;
  comprimento: number;
  largura: number;
  altura: number;
  private languageChange: Subscription;

  constructor(
    readonly languageService: LanguageService,
    readonly translateService: TranslateService,
    private readonly dadosDominioService: DadosDominioService,
    private readonly messageService: MessageService,
    private readonly autenticacaoService: AutenticacaoService,
  ) {
    if (location?.hash) {
      location.href = location.hash.substring(1);
      return;
    }

    this.contactEmail = environment.contactEmail;
    this.atualizaTraducaoPeso().then();

    this.lang = languageService.selected.code.toLowerCase();
    this.languageChange = languageService.languageChange.subscribe(lang => {
      this.lang = lang.lang.lang.toLowerCase();
      this.atualizaTraducaoPeso().then();
    });
  }

  ngOnInit() {
    this.selecionarPais(this.listaPais[0]);
    this.tipoDimensao = "inch";

    $('#cadastroEmail').modal('show');
  }

  ngOnDestroy(): void {
    this.languageChange.unsubscribe();
  }

  selecionarPais(pais: PaisModel): void {
    this.packet = {};
    this.first = {};
    this.priority = {};
    this.priorityExpress = {};
    this.express = {};
    this.pais = pais;
    this.cep = null;
    this.consultarTaxaCobranca(this.pesoLbs);
  }

  consultarTaxaCobranca(pesoLbs: number): void {
    if (!pesoLbs || !this.pais) {
      this.listaTaxaCobranca = null;
    } else {
      this.enderecoUsuario = new EnderecoUsuarioModel();
      this.enderecoUsuario.entidadePais = this.pais;
      if (this.cep) {
        this.enderecoUsuario.cep = this.cep;
      } else {
        this.enderecoUsuario.cep = "0000000000";
      }
      let comprimento = this.comprimento;
      let largura = this.largura;
      let altura = this.altura;
      if (this.dimensao && this.tipoDimensao == 'cm') {
        comprimento = Math.ceil(this.comprimento * 0.3937007874);
        largura = Math.ceil(this.largura * 0.3937007874);
        altura = Math.ceil(this.altura * 0.3937007874);
      }

      let taxaCobranca: TaxaCobrancaModel = {
        numPesoLbs: Math.ceil(pesoLbs),
        numPesoOz: 0,
        tipoOpcaoEnvio: "CORREIOS",
        pais: this.pais,
        enderecoUsuario: this.enderecoUsuario,
        comprimento: comprimento,
        largura: largura,
        altura: altura
      };

      this.dadosDominioService.consultarTaxaCobrancaPorPesoETipoOpcaoEnvio(taxaCobranca).pipe(
        take(1)
      ).subscribe(response => {
        this.listaTaxaCobranca = response.collection;
        this.lbs = pesoLbs;
        this.ajustaTaxaCobranca();
      });
    }
  }

  private ajustaTaxaCobranca(): void {
    this.listaTaxaCobranca.forEach(taxa => {
      if (taxa.flgAtivo) {
        if (taxa.tipoEnvio === 'PACKET_STANDARD') {
          this.packet.taxaCobranca = taxa;
        } else if (taxa.tipoEnvio === 'FIRST_CLASS') {
          this.first.taxaCobranca = taxa;
        } else if (taxa.tipoEnvio === 'PRIORITY_MAIL') {
          this.priority.taxaCobranca = taxa;
        } else if (taxa.tipoEnvio === 'PACKET_EXPRESS') {
          this.express.taxaCobranca = taxa;
        } else if (taxa.tipoEnvio === 'PRIORITY_MAIL_EXPRESS') {
          this.priorityExpress.taxaCobranca = taxa;
        }
      }
    });
  }

  private async atualizaTraducaoPeso(): Promise<void> {
    this.translateService.get(['inicio.simulador.fretes.quilos', 'inicio.simulador.fretes.libras']).pipe(
      take(1)
    ).subscribe(translations => {
      this.kg = {
        codigo: 'kg',
        descricao: translations['inicio.simulador.fretes.quilos']
      };

      this.lb = {
        codigo: 'lb',
        descricao: translations['inicio.simulador.fretes.libras']
      }

      this.medida = this.lb;
      this.listaMedida = [this.lb, this.kg];
    });
  }

  cadastrarEmail(): void {
    this.autenticacaoService.cadastrarEmail(this.email).pipe(
      take(1)
    ).subscribe(() => {
      $('#cadastroEmail').modal('hide');
      this.messageService.addSuccess('inicio.modal.email.cadastroSucesso');
    });
  }
}
