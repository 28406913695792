import {Component, OnInit} from '@angular/core';
import {ExemploDeclaracaoService} from "../../../shared/services/exemplo-declaracao.service";
import {faAngleUp, faBoxesPacking, faCamera, faSearch, faTimes} from "@fortawesome/free-solid-svg-icons";
import {ExemploDeclaracaoModel} from "../../../shared/models/exemplo-declaracao.model";
import {firstValueFrom} from "rxjs";
import {ExemploDeclaracaoVO} from "../../../shared/models/vo/exemplo-declaracao.vo";
import {PageModel} from "../../../shared/models/page.model";

declare const $: any;

@Component({
  selector: 'app-exemplo-declaracao-publico',
  templateUrl: './exemplo-declaracao-publico.component.html',
  styleUrls: ['./exemplo-declaracao-publico.component.scss']
})
export class ExemploDeclaracaoPublicoComponent implements OnInit {

  protected readonly search = faSearch;
  protected readonly camera = faCamera;
  protected readonly times = faTimes;
  protected readonly angleUp = faAngleUp;
  protected readonly rastreio = faBoxesPacking;

  loading: boolean;
  file: string;
  declaracao: ExemploDeclaracaoModel;
  palavraBusca: string;
  listaDetalhe: boolean[] = [];
  page = new PageModel<ExemploDeclaracaoVO>();

  constructor(
    private readonly exemploDeclaracaoService: ExemploDeclaracaoService
  ) {
    this.page.pageSize = 15;
    this.limparFormulario();
  }

  ngOnInit(): void {
    this.pesquisar();
  }

  async pesquisar(): Promise<void> {
    this.loading = true;
    this.page.collection = [];

    try {
      this.page = await firstValueFrom(this.exemploDeclaracaoService.consultarTodos(this.palavraBusca, this.page));
    } finally {
      this.loading = false;
    }
  }

  limparFormulario(): void {
    this.file = null;
    this.declaracao = new ExemploDeclaracaoModel();
  }

  toggle(element: string): void {
    $(element).collapse('toggle');
  }

  detalhe(idEnvio: number): void {
    this.listaDetalhe[idEnvio] = true;
  }
}
