import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {FormsModule} from "@angular/forms";
import {SharedModule} from "./shared/shared.module";
import {BrowserModule, Meta} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {authInterceptorProviders} from "./shared/interceptors/auth.interceptor";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from "../environments/environment";

import {registerLocaleData} from "@angular/common";
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from "angular-calendar/date-adapters/date-fns";
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  GoogleSigninButtonModule,
  SocialLoginModule
} from "@abacritt/angularx-social-login";
import {NgxEditorModule} from "ngx-editor";
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from "ngx-google-analytics";
import {LoginComponent} from "./pages/login/login.component";
import {CadastroComponent} from "./pages/home/cadastro/cadastro.component";
import {InicioComponent} from "./pages/home/inicio/inicio.component";
import {CapitalizeDirective} from "./shared/directives/capitalize.directive";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import localeEn from '@angular/common/locales/en';
import localePt from '@angular/common/locales/pt';
import localeEnExtra from '@angular/common/locales/extra/en';
import localePtExtra from '@angular/common/locales/extra/pt';
import {NoWhitespaceDirective} from "./shared/directives/no-whitespace.directive";
import { CarouselComponent } from './pages/home/inicio/carousel/carousel.component';
import {CarouselModule} from "ngx-bootstrap/carousel";
import {ExemploDeclaracaoPublicoComponent} from "./pages/home/exemplo-declaracao/exemplo-declaracao-publico.component";
import {LocalizedDatePipe} from "./shared/pipes/localized-date.pipe";
import {VisualizarExemploModule} from "./shared/components/visualizar-exemplo/visualizar-exemplo.module";
import {PaginationComponent} from "./shared/components/pagination/pagination.component";

registerLocaleData(localeEn, 'en-US', localeEnExtra);
registerLocaleData(localeEn, 'EN', localeEnExtra);
registerLocaleData(localePt, 'pt-BR', localePtExtra);
registerLocaleData(localePt, 'PT', localePtExtra);

export function translateLoaderFactory(http: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    InicioComponent,
    LoginComponent,
    CadastroComponent,
    CarouselComponent,
    ExemploDeclaracaoPublicoComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    CapitalizeDirective,
    TooltipModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'pt-BR'
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !environment.local,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    SocialLoginModule,
    SharedModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    NgxGoogleAnalyticsModule.forRoot(environment.google.analytics, null, null, environment.production),
    NgxGoogleAnalyticsRouterModule,
    NgxEditorModule.forRoot({
      locals: {
        // menu
        bold: 'Bold',
        italic: 'Italic',
        code: 'Code',
        blockquote: 'Blockquote',
        underline: 'Underline',
        strike: 'Strike',
        bullet_list: 'Bullet List',
        ordered_list: 'Ordered List',
        heading: 'Heading',
        h1: 'Header 1',
        h2: 'Header 2',
        h3: 'Header 3',
        h4: 'Header 4',
        h5: 'Header 5',
        h6: 'Header 6',
        align_left: 'Left Align',
        align_center: 'Center Align',
        align_right: 'Right Align',
        align_justify: 'Justify',
        text_color: 'Text Color',
        background_color: 'Background Color',

        // popups, forms, others...
        url: 'URL',
        text: 'Text',
        openInNewTab: 'Open in new tab',
        insert: 'Insert',
        altText: 'Alt Text',
        title: 'Title',
        remove: 'Remove',
      },
    }),
    GoogleSigninButtonModule,
    NoWhitespaceDirective,
    CarouselModule,
    LocalizedDatePipe,
    VisualizarExemploModule,
    PaginationComponent,
  ],
  exports: [
    TooltipModule,
    TranslateModule
  ],
  providers: [
    Meta,
    TooltipModule,
    TranslateModule,
    authInterceptorProviders,
    {
      provide: 'googleTagManagerId',
      useValue: environment.google.gtag,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: true,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.google.login, {
              scopes: 'profile email',
              prompt: "consent",
              oneTapEnabled: false
            })
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebook, {
              scope: 'email',
              return_scopes: true,
              enable_profile_selector: true
            })
          }
        ]
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
