<div class="modal modal-pontuacao fade" [id]="modalId" role="dialog">
  <div class="modal-dialog modal-lg-6">
    <!-- Modal content-->
    <div class="modal-content">
      <form name="formRecuperarSenha" role="form">
        <div class="modal-header">
          <div class="titulo-modal">{{'inicio.modal.pontos.titulo' | translate}}</div>
          <div class="close-modal">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
          </div>
        </div>
        <div class="modal-body">
          <div>
            <div class="text-center">
              <div class="col-lg-12">
                <div class="chamada">{{'inicio.modal.pontos.chamada' | translate}}</div>
                <br/>
                <div class="texto1">{{'inicio.modal.pontos.texto1' | translate}}</div>
                <div class="texto1-itens">{{'inicio.modal.pontos.texto1-item1' | translate}}</div>
                <div class="texto1-itens">{{'inicio.modal.pontos.texto1-item2' | translate}}</div>
                <div class="texto1-itens">{{'inicio.modal.pontos.texto1-item3' | translate}}</div>
                <div class="texto1-itens">{{'inicio.modal.pontos.texto1-item4' | translate}}</div>
                <div class="texto2">{{'inicio.modal.pontos.texto2' | translate}}</div>
                <br/>
                <div class="texto3">{{'inicio.modal.pontos.texto3' | translate}}</div>
                <br/>
                <div class="texto4">{{'inicio.modal.pontos.texto4' | translate}}</div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
