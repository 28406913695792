import {DTOModel} from "../framework/dto.model";


export class PageModel<T> extends DTOModel<T>{

  currentPage: number = 0;

  totalPages: number = 1;

  pageSize: number = 10;

  count: number;

  constructor(public sortField?: string, public sortType?: string, paged: boolean = true) {
    super();

    if (!paged) {
      this.pageSize = null;
      this.currentPage = null;
    }
  }
}
