import {EventEmitter, Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Message, MessageType} from "../framework/message";
import {MensagemModel} from "../models/mensagem.model";
import {TranslateService} from "@ngx-translate/core";
import {take} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  readonly messageEventEmitter: EventEmitter<Message> = new EventEmitter();

  constructor(
    private readonly translateService: TranslateService
  ) {
  }

  addMessage(message: string, type: MessageType, parametros: string[] = null) {
    this.translateService.get(message || 'global.falha.geral').pipe(
      take(1)
    ).subscribe(m => {
      let msg = m || message;
      if (parametros) {
        parametros.forEach((p, i) => {
          msg = msg.replaceAll(`{${i}}`, p);
        });
      }

      this.messageEventEmitter.emit(new Message(msg, type));
    });
  }

  addSuccess(message: string | MensagemModel[]) {
    if (Array.isArray(message)) {
      message.forEach(m => this.addMessage(m.mensagem, MessageType.ALERT_TYPE_SUCCESS));
    } else {
      this.addMessage(message, MessageType.ALERT_TYPE_SUCCESS);
    }
  }

  addWarning(message: string) {
    this.addMessage(message, MessageType.ALERT_TYPE_WARNING);
  }

  addDanger(message: string | MensagemModel[] | HttpErrorResponse) {
    if (message instanceof HttpErrorResponse) {
      if (message.error instanceof Blob) {
        const reader = new FileReader();
        reader.addEventListener('loadend', (e) => {
          const text = e.target.result as string;
          const json = JSON.parse(text);
          this.addDanger(json.error.message || json.message);
        });
        reader.readAsText(message.error);
        return;
      }

      switch (message.status) {
        case 0:
        case 503:
          this.addMessage('system.down', MessageType.ALERT_TYPE_DANGER);
          break;
        case 401:
        case 403:
          this.addMessage('system.accessDenied', MessageType.ALERT_TYPE_DANGER);
          break;
        case 404:
          this.addMessage('system.notFound', MessageType.ALERT_TYPE_DANGER);
          break;
        case 400:
        case 500:
          this.addMessage((message.error ? message.error.message : null) || message.message ||
            'system.internalError', MessageType.ALERT_TYPE_DANGER);
          break;
        case 504:
          this.addMessage('system.timeout', MessageType.ALERT_TYPE_DANGER);
          break;
        default:
          this.addMessage(message.message, MessageType.ALERT_TYPE_DANGER);
      }
    } else if (Array.isArray(message)) {
      message.forEach(m => {
        let type = m.tipo === 'ERRO' ? MessageType.ALERT_TYPE_DANGER : MessageType.ALERT_TYPE_WARNING;
        this.addMessage(m.mensagem, type, m.parametros);
      });
    } else {
      this.addMessage(message, MessageType.ALERT_TYPE_DANGER);
    }
  }
}
