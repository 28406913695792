import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from "@angular/common";
import {TranslateService} from "@ngx-translate/core";

@Pipe({
  name: 'moeda',
  pure: false
})
export class MoedaPipe implements PipeTransform {

  constructor(
    private translateService: TranslateService
  ) {
  }

  transform(value: any, currency = true): any {
    if (value || value === 0) {
      let pipe = new DecimalPipe(this.translateService.currentLang);
      return (currency ? 'US$ ' : '') + pipe.transform(value || 0, '1.2-2');
    }

    return value;
  }
}
