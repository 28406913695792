<div class="conteudo nao-autenticado">
  <div class="titulo-pagina">
    <div class="container-fluid">
      <h1>{{ 'main.menu.exemploDeclaracao.titulo' | translate }}</h1>
    </div>
  </div>

  <div class="corpo-nao-autenticado">
    <div class="container-fluid">
      <form role="search">
        <div class="col-lg-4 campo-pesquisa-ajuda">
          <div class="input-group">
            <input type="text" class="form-control" focus enterkeyhint="search" inputmode="search"
                   [(ngModel)]="palavraBusca" id="palavraBusca" name="palavraBusca"
                   placeholder="{{'menu.ajuda.input.pesquisa' | translate}}">
            <div class="input-group-btn">
              <button class="btn btn-primary btn-pesquisa" type="submit" (click)="pesquisar()" aria-label="Pesquisar"
                      enterkeyhint="search">
                <fa-icon [icon]="search"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </form>

      <form name="formDeclaracao" role="form" aria-labelledby="titulo">
        <div class="col-lg-12">
          <div class="headertable m-t-1 m-b-3">
            <app-loading [list]="page.collection" [loading]="loading"></app-loading>
            <table class="table table-striped table-hover table-condensed tabela-itens responsive"
                   aria-labelledby="title" *ngIf="page.collection?.length > 0">
              <thead>
              <tr>
                <th scope="col">
                  {{ 'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.declaracao' | translate }}
                </th>
                <th scope="col">
                  {{ 'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.data' | translate }}
                </th>
                <th scope="col">
                  {{ 'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.prazoEntrega' | translate }}
                </th>
                <th scope="col">
                  {{ 'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.frete' | translate }}
                </th>
                <th scope="col">
                  {{ 'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.seguro' | translate }}
                </th>
                <th scope="col">
                  {{ 'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.valorDeclarado' | translate }}
                </th>
                <th scope="col">
                  {{ 'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.imposto' | translate }}
                </th>
                <th scope="col">
                  {{ 'global.opcoes' | translate }}
                </th>
              </tr>
              </thead>
              <tbody>
              <ng-container *ngFor="let item of page.collection">
                <tr style="margin-bottom: 0">
                  <td
                    [attr.data-title]="'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.declaracao' | translate">
                    {{ item.descricao | emptyValue }}
                  </td>
                  <td
                    [attr.data-title]="'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.data' | translate">
                    {{ item.data | localizedDate | emptyValue }}
                  </td>
                  <td
                    [attr.data-title]="'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.prazoEntrega' | translate">
                    {{ item.prazo | emptyValue }}
                    {{ 'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.dias' | translate }}
                  </td>
                  <td
                    [attr.data-title]="'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.frete' | translate">
                    <app-country-flags country="US"></app-country-flags>
                    {{ item.frete | moeda | emptyValue }}
                  </td>
                  <td
                    [attr.data-title]="'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.seguro' | translate">
                    <app-country-flags country="US"></app-country-flags>
                    {{ item.seguro | moeda | emptyValue }}
                  </td>
                  <td
                    [attr.data-title]="'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.valorDeclarado' | translate">
                    <app-country-flags country="US"></app-country-flags>
                    {{ item.valorDeclarado | moeda | emptyValue }}
                  </td>
                  <td
                    [attr.data-title]="'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.imposto' | translate">
                    <app-country-flags *ngIf="item.imposto" country="BR"></app-country-flags>
                    <ng-container *ngIf="item.imposto"> BRL</ng-container>
                    {{ item.imposto | moeda: false | emptyValue }}
                  </td>
                  <td [attr.data-title]="'global.opcoes' | translate" class="opcoes-tabela">
                    <a (click)="detalhe(item.idEnvio); toggle('#collapse' + item.id)" class="pointer"
                       placement="top" [tooltip]="'global.detalhes' | translate">
                      <fa-icon
                        [icon]="angleUp"
                        [rotate]="td.getAttribute('aria-expanded') === 'true' ? null : 180"
                      ></fa-icon>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td colspan="9" id="collapse{{item.id}}" #td aria-expanded="false"
                      class="panel-collapse collapse cell text-left">
                    <app-visualizar-exemplo
                      [exemplo]="item"
                      *ngIf="listaDetalhe[item.idEnvio]"
                    ></app-visualizar-exemplo>
                  </td>
                </tr>
              </ng-container>
              </tbody>
            </table>
            <app-pagination
              [(page)]="page"
              (load)="pesquisar()"
            ></app-pagination>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
