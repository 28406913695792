import {inject, NgModule} from '@angular/core';
import {RouterModule, Routes, UrlSegment} from '@angular/router';
import {InicioComponent} from "./pages/home/inicio/inicio.component";
import {CommonModule} from "@angular/common";
import {CadastroComponent} from "./pages/home/cadastro/cadastro.component";
import {PublicRouteGuard} from "./shared/guards/public-route-guard";
import {LoginRouteGuard} from "./shared/guards/login-route-guard";
import {AdminRouteGuard} from "./shared/guards/admin-route-guard";
import {AfiliadoRouteGuard} from "./shared/guards/afiliado-route-guard";
import {LoginComponent} from "./pages/login/login.component";
import {ExemploDeclaracaoPublicoComponent} from "./pages/home/exemplo-declaracao/exemplo-declaracao-publico.component";

const routes: Routes = [
  {
    path: 'inicial',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: 'inicio',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: '',
    component: InicioComponent,
    canActivate: [() => inject(PublicRouteGuard).canActivate()],
    data: {
      title: 'system.title',
      description: 'system.description',
      keywords: 'system.keywords'
    }
  },
  {
    path: 'associate/:afiliado',
    component: CadastroComponent,
    canActivate: [() => inject(AfiliadoRouteGuard).canActivate()]
  },
  {
    path: 'affiliate/:afiliado',
    component: CadastroComponent,
    canActivate: [() => inject(AfiliadoRouteGuard).canActivate()]
  },
  {
    path: 'afiliado/:afiliado',
    component: CadastroComponent,
    canActivate: [() => inject(AfiliadoRouteGuard).canActivate()]
  },
  {
    path: 'exemplo-declaracao',
    component: ExemploDeclaracaoPublicoComponent,
    canActivate: [() => inject(PublicRouteGuard).canActivate()]
  },
  {
    matcher: url => {
      if (location.pathname.startsWith('/afiliado=')) {
        return {
          consumed: url,
          posParams: {
            afiliado: new UrlSegment(location.pathname.substring('/afiliado='.length), {})
          }
        };
      } else if (location.pathname.startsWith('/associate=')) {
        return {
          consumed: url,
          posParams: {
            afiliado: new UrlSegment(location.pathname.substring('/associate='.length), {})
          }
        };
      }

      return null;
    },
    redirectTo: 'afiliado/:afiliado',
    pathMatch: 'full'
  },
  {
    path: 'quemSomos',
    redirectTo: 'quem-somos',
    pathMatch: "full"
  },
  {
    path: 'redirecionamento',
    loadChildren: () => (import('./pages/home/redirecionamento/redirecionamento.module')).then(x => x.RedirecionamentoModule),
    canActivate: [() => inject(PublicRouteGuard).canActivate()]
  },
  {
    path: 'compraViajabox',
    redirectTo: 'compra-viajabox',
    pathMatch: 'full'
  },
  {
    path: 'compra-viajabox',
    loadChildren: () => (import('./pages/home/compra-viajabox/compra-viajabox.module')).then(x => x.CompraViajaboxModule),
    canActivate: [() => inject(PublicRouteGuard).canActivate()]
  },
  {
    path: 'vantagens',
    loadChildren: () => (import('./pages/home/vantagens/vantagens.module')).then(x => x.VantagensModule),
    canActivate: [() => inject(PublicRouteGuard).canActivate()]
  },
  {
    path: 'lojasSugeridas',
    redirectTo: 'lojas-sugeridas',
    pathMatch: 'full'
  },
  {
    path: 'lojas-sugeridas',
    loadChildren: () => (import('./pages/home/lojas-sugeridas/lojas-sugeridas.module')).then(x => x.LojasSugeridasModule),
    canActivate: [() => inject(PublicRouteGuard).canActivate()]
  },
  {
    path: 'centralAjuda',
    redirectTo: 'central-ajuda',
    pathMatch: 'full'
  },
  {
    path: 'central-ajuda',
    loadChildren: () => (import('./pages/home/central-ajuda/central-ajuda.module')).then(x => x.CentralAjudaModule),
    canActivate: [() => inject(PublicRouteGuard).canActivate()]
  },
  {
    path: 'calculadora-frete',
    loadChildren: () => (import('./pages/home/calculadora-frete/calculadora-frete.module')).then(x => x.CalculadoraFreteModule)
  },
  {
    path: 'calculadora-importacao',
    loadChildren: () => (import('./pages/home/calculadora-importacao/calculadora-importacao.module')).then(x => x.CalculadoraImportacaoModule)
  },
  {
    path: 'confirmacao-cadastro',
    loadChildren: () => (import('./pages/home/confirmacao-cadastro/confirmacao-cadastro.module')).then(x => x.ConfirmacaoCadastroModule),
    canActivate: [() => inject(PublicRouteGuard).canActivate()]
  },
  {
    path: 'politicaPrivacidade',
    redirectTo: 'politica-privacidade',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'main.login.entrar.titulo'
    },
    canActivate: [() => inject(PublicRouteGuard).canActivate()]
  },
  {
    path: 'cadastro',
    component: CadastroComponent,
    data: {
      title: 'main.menu.criar.conta'
    },
    canActivate: [() => inject(PublicRouteGuard).canActivate()]
  },
  {
    path: 'cursoSobreImportacao',
    redirectTo: 'curso-sobre-importacao',
    pathMatch: 'full'
  },
  {
    path: 'curso-sobre-importacao',
    loadChildren: () => (import('./pages/home/curso-sobre-importacao/curso-sobre-importacao.module')).then(x => x.CursoSobreImportacaoModule),
    canActivate: [() => inject(PublicRouteGuard).canActivate()]
  },
  {
    path: 'termosPrivacidade',
    redirectTo: 'termos-privacidade',
    pathMatch: 'full'
  },
  {
    path: 'termos-privacidade',
    loadChildren: () => (import('./pages/home/termos-privacidade/termos-privacidade.module')).then(x => x.TermosPrivacidadeModule),
    canActivate: [() => inject(PublicRouteGuard).canActivate()]
  },
  {
    path: 'quem-somos',
    loadChildren: () => (import('./pages/home/quem-somos/quem-somos.module')).then(x => x.QuemSomosModule),
    canActivate: [() => inject(PublicRouteGuard).canActivate()]
  },
  {
    path: 'unsubscribe',
    loadChildren: () => (import('./pages/home/unsubscribe/unsubscribe.module')).then(x => x.UnsubscribeModule),
    canActivate: [() => inject(PublicRouteGuard).canActivate()]
  },
  {
    path: 'admin',
    loadChildren: () => (import('./pages/admin/admin.module')).then(x => x.AdminModule),
    canActivate: [() => inject(AdminRouteGuard).canActivate()]
  },
  {
    path: 'envios',
    loadChildren: () => (import('./pages/envios/envios.module')).then(x => x.EnviosModule),
    canActivate: [() => inject(LoginRouteGuard).canActivate()]
  },
  {
    path: 'geral',
    loadChildren: () => (import('./pages/geral/geral.module')).then(x => x.GeralModule),
    canActivate: [() => inject(LoginRouteGuard).canActivate()]
  },
  {
    path: 'compra-assistida',
    loadChildren: () => (import('./pages/compra-assistida/compra-assistida.module')).then(x => x.CompraAssistidaModule),
    canActivate: [() => inject(LoginRouteGuard).canActivate()]
  },
  {
    path: '**',
    pathMatch: 'full',
    loadChildren: () => (import('./pages/not-found/not-found.module')).then(x => x.NotFoundModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    CommonModule
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
