import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FocusDirective} from "./directives/focus.directive";
import {EmptyValuePipe} from "./pipes/empty-value.pipe";
import {TopoGeralComponent} from "./components/topo-geral/topo-geral.component";
import {TopoInternoComponent} from "./components/topo-interno/topo-interno.component";
import {FooterGeralComponent} from "./components/footer-geral/footer-geral.component";
import {FooterInternoComponent} from "./components/footer-interno/footer-interno.component";
import {RouterModule} from "@angular/router";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {ListaLojasComponent} from './components/lista-lojas/lista-lojas.component';
import {TermosComponent} from './components/termos/termos.component';
import {TranslateModule} from "@ngx-translate/core";
import {MoedaPipe} from "./pipes/moeda.pipe";
import {CountryFlagsComponent} from "./components/country-flags/country-flags.component";
import {FotoUsuarioComponent} from './dialogs/foto-usuario/foto-usuario.component';
import {ImageCropperModule} from "ngx-img-cropper";
import {PontuacaoComponent} from './dialogs/pontuacao/pontuacao.component';
import {CreditosComponent} from './dialogs/creditos/creditos.component';
import {NumberDirective} from "./directives/number.directive";
import {LoadingComponent} from './components/loading/loading.component';
import {LoadingSpinnerComponent} from './components/loading-spinner/loading-spinner.component';
import {MoneyDirective} from "./directives/money.directive";
import {NgxEditorModule} from "ngx-editor";
import {AlertMessageComponent} from "./components/alert-message/alert-message.component";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {IdiomaComponent} from './components/idioma/idioma.component';
import {DescricaoDeclaracaoEnvioComponent} from "./components/declaracao-envio/descricao-declaracao-envio.component";
import {PhonePipe} from "./pipes/phone.pipe";
import {LocalizedDateTimePipe} from "./pipes/localized-date-time.pipe";
import {DescricaoPaisComponent} from "./components/descricao-pais/descricao-pais.component";
import {SortingComponent} from "./components/sorting/sorting.component";
import {NoWhitespaceDirective} from "./directives/no-whitespace.directive";

@NgModule({
  declarations: [
    EmptyValuePipe,
    MoedaPipe,
    PhonePipe,
    CountryFlagsComponent,
    FocusDirective,
    NumberDirective,
    MoneyDirective,
    TopoGeralComponent,
    TopoInternoComponent,
    FooterGeralComponent,
    FooterInternoComponent,
    ListaLojasComponent,
    TermosComponent,
    FotoUsuarioComponent,
    PontuacaoComponent,
    CreditosComponent,
    LoadingComponent,
    DescricaoPaisComponent,
    LoadingSpinnerComponent,
    AlertMessageComponent,
    IdiomaComponent,
    DescricaoDeclaracaoEnvioComponent,
    PhonePipe,
    SortingComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    TooltipModule,
    TranslateModule,
    ReactiveFormsModule,
    ImageCropperModule,
    NgxEditorModule,
    FontAwesomeModule,
    LocalizedDateTimePipe,
    NgOptimizedImage,
    NoWhitespaceDirective
  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgxEditorModule,
    FontAwesomeModule,
    MoneyDirective,
    CountryFlagsComponent,
    ReactiveFormsModule,
    TopoGeralComponent,
    TopoInternoComponent,
    FooterGeralComponent,
    DescricaoPaisComponent,
    TooltipModule,
    TranslateModule,
    FooterInternoComponent,
    EmptyValuePipe,
    PhonePipe,
    MoedaPipe,
    ListaLojasComponent,
    TermosComponent,
    ImageCropperModule,
    FocusDirective,
    NumberDirective,
    LoadingComponent,
    LoadingSpinnerComponent,
    AlertMessageComponent,
    DescricaoDeclaracaoEnvioComponent,
    PhonePipe,
    NgOptimizedImage,
    SortingComponent
  ]
})
export class SharedModule {
}
