import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ReadonlyService} from './readonly.service';
import {MessageService} from "../services/message.service";
import {PageModel} from "../models/page.model";

/**
 * Base class for CRUD services.
 *
 * @typeParam T - the entity class type.
 * @param <K> - the ID class type.
 */
export abstract class BaseService<T, K> extends ReadonlyService<T, K> {

  protected constructor(
    readonly url: string,
    readonly http: HttpClient,
    readonly messageService: MessageService
  ) {
    super(url, http, messageService);
  }

  create(object: T): Observable<T> {
    return this.http.post<T>(this.baseURL, object);
  }

  update(object: T): Observable<T> {
    return this.http.put<T>(this.baseURL, object);
  }

  delete(id: K): Observable<void> {
    return this.http.delete<void>(`${this.baseURL}/${id}`);
  }

  objectToBlob(object: any): Blob {
    let byteCharacters = JSON.stringify(object);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], {
      type: 'application/json'
    });
  }

  setPagination(page: PageModel<any>): HttpParams {
    let params = new HttpParams();
    if (page) {
      if (page.pageSize) {
        params = params.set('pageSize', page.pageSize);
      }

      if (page.currentPage != null) {
        params = params.set('page', page.currentPage)
      }

      if (page.sortType) {
        params = params.set('sortType', page.sortType)
      }

      if (page.sortField) {
        params = params.set('sortField', page.sortField)
      }
    }

    return params;
  }
}
