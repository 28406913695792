import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-country-flags',
  templateUrl: './country-flags.component.html',
  styleUrls: ['./country-flags.component.scss']
})
export class CountryFlagsComponent {

  @Input()
  imageClass = '';

  @Input()
  country: string = 'br';

  @Input()
  alt: string;
}
