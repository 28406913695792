import {faCalculator, faPlayCircle} from "@fortawesome/free-solid-svg-icons";
import {Component, OnInit} from "@angular/core";

declare const $: any;

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  calculator = faCalculator;
  video = faPlayCircle;
  showVideo = false;

  ngOnInit(): void {
    const modal = $("#modalVideo");
    modal.on("hidden.bs.modal", () => {
      this.setVideo(false);
    });
  }

  setVideo(show: boolean): void {
    this.showVideo = false;

    const modal = $("#modalVideo");
    if (show) {
      this.showVideo = show;
      modal.modal('show');
    } else {
      modal.modal('hide');
    }
  }

}
