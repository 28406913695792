import {Component, Input} from '@angular/core';
import {MessageService} from "../../services/message.service";
import {CambioRealService} from "../../services/cambio-real.service";
import {take} from "rxjs";
import {Utils} from "../../framework/utils";

@Component({
  selector: 'app-creditos',
  templateUrl: './creditos.component.html',
  styleUrls: ['./creditos.component.scss']
})
export class CreditosComponent {

  valorCredito: string;

  @Input()
  modalId = 'modalCreditos';

  constructor(
    private readonly messageService: MessageService,
    private readonly cambioRealService: CambioRealService
  ) {
  }

  registrarCredito(): void {
    let value = Utils.toNumber(this.valorCredito);
    if (!value || value <= 0) {
      this.messageService.addWarning('menu.administrador.gerenciarTudo.gerenciarCreditos.valorCreditoObrigatorio');
    } else {
      this.cambioRealService.registrarCredito(value).pipe(
        take(1)
      ).subscribe({
        next: response => {
          if (response && response.status === 'success') {
            this.valorCredito = null;
            document.location = response.data.checkout;
          } else {
            this.messageService.addDanger(response.msgs);
          }
        },
        error: error => this.messageService.addDanger(error)
      });
    }
  }
}
