import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../../../environments/environment";
import {filter, Subject, take} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {TokenStorageService} from "./token-storage.service";

export interface Lang {
  flag: string;
  name: string;
  code: string;
  lang: string;
  alt: string;
  prod?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  languageChange = new Subject<{
    lang: Lang,
    old: Lang
  }>();

  private languages: Lang[] = [
    {
      flag: 'assets/img/bandeira-brasil.webp',
      code: 'PT',
      name: 'BRA – PT',
      lang: 'pt-BR',
      alt: 'Bandeira do Brasil',
      prod: true
    },
    {
      flag: 'assets/img/bandeira-eua.webp',
      code: 'EN',
      name: 'USA – EN',
      lang: 'en-US',
      alt: "United State's flag",
      prod: true
    }
  ];

  current: string;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly translateService: TranslateService,
    private readonly tokenStorageService: TokenStorageService
  ) {
  }

  setLanguages(idioma: string = null): void {
    this.translateService.addLangs(['pt-BR', 'en-US']);
    this.translateService.setDefaultLang('pt-BR');

    this.lang = idioma || 'pt-BR';
    this.activatedRoute.queryParams.pipe(
      filter(param => param.lang),
      take(1)
    ).subscribe(params => {
      setTimeout(() =>      this.lang = params.lang, 100);
    });
  }

  get selected(): Lang {
    return this.languages.find(x => x.lang === this.current);
  }

  availables(): Lang[] {
    if (environment.production) {
      return this.languages.filter(x => x.prod);
    }

    return this.languages;
  }

  set lang(lang: string) {
    const old = this.selected || this.languages.find(x => x.lang === 'pt-BR');

    let l = this.languages.find(x => (x.lang === lang || x.code.toLowerCase() === lang.toLowerCase())
      && (!environment.production || x.prod)) || this.languages.find(x => x.lang === 'pt-BR');
    if (l.lang !== this.translateService.currentLang) {
      this.current = l.lang;
      this.translateService.use(l.lang);
      this.languageChange.next({
        lang: l,
        old
      });
      document.querySelector('html').lang = l.lang;
    }
  }
}
