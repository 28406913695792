import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService} from "../framework/base.service";
import {Observable, take} from "rxjs";
import {AlertaUsuarioModel} from "../models/alerta-usuario.model";
import {NotificarUsuarioDto} from "../models/dto/notificar-usuario.dto";
import {AlertaUsuarioDto} from "../models/dto/alerta-usuario.dto";
import {UsuarioDto} from "../models/dto/usuario.dto";
import {TokenStorageService} from "./token-storage.service";
import {MessageService} from "./message.service";
import {PageModel} from "../models/page.model";

@Injectable({
  providedIn: 'root'
})
export class AlertaService extends BaseService<AlertaUsuarioModel, number> {

  constructor(
    readonly http: HttpClient,
    readonly messageService: MessageService,
    private readonly tokenStorageService: TokenStorageService
  ) {
    super('/alertaRestService', http, messageService);
  }

  enviarNotificaoUsuario(notificarUsuarioDTO: NotificarUsuarioDto, arquivo1: Blob, arquivo2: Blob): Observable<NotificarUsuarioDto> {
    const formData = new FormData();
    if (arquivo1) {
      formData.append('file1', arquivo1);
    }
    if (arquivo2) {
      formData.append('file2', arquivo2);
    }
    formData.append('json', this.objectToBlob(notificarUsuarioDTO));

    return this.http.post<NotificarUsuarioDto>(`${this.baseURL}/enviarNotificaoUsuario`, formData).pipe(
      this.mapError
    );
  }

  notificaUsuario(notificarUsuarioDTO: NotificarUsuarioDto): Observable<NotificarUsuarioDto> {
    return this.http.post<NotificarUsuarioDto>(`${this.baseURL}/notificaUsuario`, notificarUsuarioDTO).pipe(
      this.mapError
    );
  }

  marcarParaEnvio(id: number): Observable<void> {
    return this.http.put<any>(`${this.baseURL}/${id}/marcar-envio`, {}).pipe(
      this.mapError
    );
  }

  marcarParaEnvioPesquisa(usuario: number, assunto: string, tipo: string, confirmado: boolean, enviado: boolean): Observable<void> {
    let params = new HttpParams();
    if (usuario) {
      params = params.set('usuario', usuario);
    }
    if (assunto) {
      params = params.set('assunto', assunto);
    }
    if (tipo) {
      params = params.set('tipo', tipo);
    }
    if (confirmado) {
      params = params.set('confirmado', confirmado);
    }
    if (enviado) {
      params = params.set('enviado', enviado);
    }

    return this.http.put<any>(`${this.baseURL}/marcar-envio`, {}, {
      params
    }).pipe(
      this.mapError
    );
  }

  recuperarAlertas(pagination: PageModel<AlertaUsuarioModel>, usuario: number, assunto: string, tipo: string, confirmado: boolean, enviado: boolean): Observable<PageModel<AlertaUsuarioModel>> {
    let params = this.setPagination(pagination);
    if (usuario) {
      params = params.set('usuario', usuario);
    }
    if (assunto) {
      params = params.set('assunto', assunto);
    }
    if (tipo) {
      params = params.set('tipo', tipo);
    }
    if (confirmado) {
      params = params.set('confirmado', confirmado);
    }
    if (enviado) {
      params = params.set('enviado', enviado);
    }

    return this.http.get<PageModel<AlertaUsuarioModel>>(this.baseURL, {
      params
    }).pipe(
      this.mapError
    );
  }

  marcarLeituraAlerta(alertaUsuario: AlertaUsuarioModel): Observable<AlertaUsuarioDto> {
    return this.http.post<AlertaUsuarioDto>(`${this.baseURL}/marcarLeituraAlerta`, alertaUsuario).pipe(
      this.mapError
    );
  }

  atualizarListaAlertaPorUsuario(): void {
    if (this.tokenStorageService.isAuthenticated()) {
      this.http.post<UsuarioDto>(`${this.baseURL}/recuperarListaAlertaPorUsuario`, {}).pipe(
        take<UsuarioDto>(1),
        this.mapError
      ).subscribe(response => TokenStorageService.setGlobals(response));
    }
  }
}
