import {Component, Input, OnDestroy} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {Subscription} from "rxjs";
import {LanguageService} from "../../services/language.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-termos',
  templateUrl: './termos.component.html',
  styleUrls: ['./termos.component.scss']
})
export class TermosComponent implements OnDestroy {

  @Input()
  termosId: string = 'termosId';

  contactEmail: string;
  lang: string;
  private languageChange: Subscription;

  constructor(
    readonly router: Router,
    readonly languageService: LanguageService
  ) {
    this.contactEmail = environment.contactEmail;

    this.lang = languageService.selected.code.toLowerCase();
    this.languageChange = languageService.languageChange.subscribe(lang => this.lang = lang.lang.lang.toLowerCase());
  }

  ngOnDestroy(): void {
    this.languageChange.unsubscribe();
  }
}
