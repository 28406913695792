import {EnvioModel} from "./envio.model";
import {ArquivoModel} from "./arquivo.model";

export class ExemploDeclaracaoModel {

  public id?: number;

  public envio?: EnvioModel;

  public prazo?: number;

  public codRastreio?: string;

  public imagemRastreio?: ArquivoModel;

  public imagemImposto?: ArquivoModel;

  public valorTotalAlfandega?: number;

}
