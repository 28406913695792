import 'zone.js/plugins/zone-error';

export const environment = {
  production: true,
  local: false,
  baseURL: '/rest',
  contactEmail: 'contato@viajabox.com',
  marketingEmail: 'marketing@viajabox.com',
  google: {
    gtag: 'GTM-MH7VVWZ',
    analytics: 'G-Z04F5QZ3JF',
    login: '840902889731-qo4i22ei60tnv1m8cmt8kin1h9leltnh.apps.googleusercontent.com'
  },
  facebook: '384862779252834',
  paypalId: 'AdqwJ8EhWXB9sYtzJ-6nX6GPYPBI02JS4DpuhtK7bAmy9tahGfjNB2Pr7UgAFGqx_0-d7o-AHbZWiY7P',
  paypalCurrency: 'USD'
};
