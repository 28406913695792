import {Component, ElementRef, Input, OnDestroy, ViewChild} from '@angular/core';
import {Subscription, take} from "rxjs";
import {LanguageService} from "../../services/language.service";
import {TranslateService} from "@ngx-translate/core";
import {ItemCaixaModel} from "../../models/item-caixa.model";
import {ItemCaixaUsuarioService} from "../../services/item-caixa-usuario.service";
import {MessageService} from "../../services/message.service";
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss']
})
export class ImagePreviewComponent implements OnDestroy {

  protected readonly arrowRight = faArrowRight;
  protected readonly arrowLeft = faArrowLeft;
  protected frameRatio = 15;

  loading = false;
  image360: HTMLImageElement;
  foto360: boolean;
  total = 0;
  idItemCaixa: number;
  frame = 0;

  lastClientWidth = null;

  downFlag = false;
  downPosX = 0;
  isIPhone = false;

  @Input()
  sliderValue = 40;

  @Input()
  src = '/assets/img/nopic.webp';

  mobile$ = false;

  @Input()
  set mobile(mobile: boolean) {
    this.mobile$ = mobile;
    if (mobile) {
      this.sliderValue = 100;
      this.frameRatio = 5
    }
  }

  @ViewChild("divContainer")
  divContainer: ElementRef;

  @ViewChild("imageContainer")
  imageContainer: ElementRef;

  @Input()
  set caixa(c: number) {
    this.loading = false;
    this.foto360 = false;
    this.idItemCaixa = null;
    this.image360 = null;
    this.total = 0;

    if (c) {
      this.src = `rest/arquivoRestService/recuperarCaixasUsuario/${c}`;
    } else {
      this.src = '/assets/img/nopic.webp';
    }
  }

  @Input()
  set itemCaixa(caixa: ItemCaixaModel) {
    this.caixa = null;

    if (!this.mobile$) {
      this.sliderValue = 40;
    }

    if (caixa) {
      this.foto360 = caixa.bolFoto360;
      if (!caixa.bolFoto360) {
        // Usa o caminho comum de caixa sem foto 360º
        this.caixa = caixa.idArquivo;
      } else {
        this.src = `rest/arquivoRestService/recuperarCaixasUsuario/${caixa.idArquivo}`;
        this.total = caixa.foto360Count;
        this.lastClientWidth = null;
        this.idItemCaixa = caixa.idItemCaixa;

        this.loadImage(this.itemCaixaUsuarioService.getImagem360Url(this.idItemCaixa));
      }
    }
  }

  @Input()
  status: boolean;

  private languageChange: Subscription;
  alt: string;

  constructor(
    readonly languageService: LanguageService,
    private readonly messageService: MessageService,
    private readonly translateService: TranslateService,
    private readonly itemCaixaUsuarioService: ItemCaixaUsuarioService
  ) {
    this.languageChange = languageService.languageChange.subscribe(() => {
      this.updateAlt();
    });

    this.isIPhone = (/iPhone/i).test(navigator.userAgent);
    this.updateAlt();
  }

  ngOnDestroy(): void {
    this.languageChange.unsubscribe();
  }

  get imageWidth(): number {
    let current = this.divContainer?.nativeElement.clientWidth || 1;

    if (this.lastClientWidth != current && current != 1) {
      // x2 para HiDPI
      this.loadImage(this.itemCaixaUsuarioService.getImagem360Url(this.idItemCaixa));
    }

    this.lastClientWidth = current;
    return this.lastClientWidth;
  }

  private loadImage = (image: string): void => {
    this.image360 = document.createElement('img');
    this.image360.loading = 'eager';
    this.image360.onerror = () => {
      this.messageService.addWarning('notificarUsuarios.erroLeitura');
    }

    this.loading = true;

    this.image360.onload = () => {
      this.frame = 0;
      this.drawCanvas();
      this.loading = false;
    }
    this.image360.onerror = () => {
      this.total = 1;
      this.image360.src = '/assets/img/nopic.webp';
      this.loading = false;
    }

    this.image360.src = image;
  }

  get loadingImage(): boolean {
    return this.loading;
  }

  private drawCanvas = (): void => {
    if (this.image360.complete) {
      const height = this.image360.height / this.total;
      const pos = this.frame * height;

      const canvas = document.createElement("canvas");
      canvas.width = this.image360.width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = "high";
      ctx.drawImage(this.image360, 0, pos, canvas.width, height, 0, 0, canvas.width, height);
      this.src = canvas.toDataURL("image/png");
    } else {
      this.image360.onload = () => this.drawCanvas();
    }
  }

  onSliderChange = (): void => {
    if (this.foto360) {
      this.drawCanvas();
    }
  }

  private updateAlt(): void {
    this.translateService.get('compraAssistida.produto.imagem').pipe(
      take(1)
    ).subscribe(t => this.alt = t);
  }

  private getTouchEvent(event: TouchEvent): Touch {
    if (event.touches?.length) {
      return event.touches[0];
    } else if (event.changedTouches?.length) {
      return event.changedTouches[0];
    }

    return (this.isIPhone ? event.targetTouches[0] : event) as Touch;
  };

  mouseDown(event: MouseEvent): void {
    this.downFlag = true;
    this.downPosX = event.pageX;
  }

  touchDown(e: TouchEvent): void {
    this.downFlag = true;
    let event = this.getTouchEvent(e);
    this.downPosX = event.pageX;
  }

  mouseUp() {
    this.downFlag = false;
    this.downPosX = 0;
  }

  private move(pageX: number): void {
    if (!this.downFlag) {
      return;
    }

    let frame = this.fixDirection((pageX - this.downPosX) / this.frameRatio);

    if (frame !== 0) {
      this.frame -= frame;
      if (this.frame < 0) {
        this.frame += this.total;
      }
      this.frame %= this.total
      this.downPosX = pageX;
      this.drawCanvas();
    }
  }

  moveFrameTouch(e: TouchEvent): void {
    let event = this.getTouchEvent(e);
    this.move(event.pageX);
  }

  moveFrame(event: MouseEvent): void {
    this.move(event.pageX);
  }

  fixDirection(val: number): number {
    if (val >= 0) {
      return Math.floor(val)
    } else {
      return Math.floor(val) + 1;
    }
  }
}
