import {PaisModel} from "./pais.model";
import {UsuarioModel} from "./usuario.model";

export class EnderecoUsuarioModel {

  public idEndereco?: number;

  public nome?: string;
  public sobrenome?: string;
  public dscEndereco?: string;
  public dscEstado?: string;
  public cidade?: string;
  public cep?: string;
  public dscReferencia?: string;
  public telefonePrincipal?: string;
  public telefoneAlternativo?: string;
  public cpf?: string;
  public cnpj?: string;

  public entidadePais?: PaisModel;
  public usuario?: UsuarioModel;

  public flgAtivo?: boolean;

  public dtInclusao?: Date;
  public dtUltimaAlteracao?: Date;
}
