import {latinize} from "ngx-bootstrap/typeahead";
import {AsYouType, CountryCode} from "libphonenumber-js";

declare const $: any;

export class Utils {

  static formataTelefone(value: string, country: string): string {
    if (value) {
      return new AsYouType(country as CountryCode).input(value);
    }

    return value;
  }

  static formataCpf(number: string): string {
    if (!number) {
      return '';
    }

    let v = number;
    if (number.length > 14) {
      v = number.substring(0, 14);
    }
    v = v.replace(/\D/g, "");
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    return v;
  }

  static formataCnpj(number: string): string {
    if (!number) {
      return '';
    }

    let digits = number.replace(/\D/g, "");
    digits = digits.padStart(14, '0');

    return digits.slice(0, 14).split('').reduce((acc, digit, index) => {
      const result = `${acc}${digit}`;
      const DOT_INDEXES = [1, 4];
      const SLASH_INDEXES = [7];
      const HYPHEN_INDEXES = [11];

      if (!this.isLastChar(index, digits)) {
        if (DOT_INDEXES.includes(index)) return `${result}.`;
        if (SLASH_INDEXES.includes(index)) return `${result}/`;
        if (HYPHEN_INDEXES.includes(index)) return `${result}-`;
      }

      return result;
    }, '');
  }

  static isLastChar(index: number, input: string): boolean {
    return index === input.length - 1;
  }

  static blobToDataURL(blob: Blob, callback: (_: string) => void) {
    let a = new FileReader();
    a.onload = function (e) {
      if (e.target.result instanceof ArrayBuffer) {
        callback(String.fromCharCode.apply(null, new Uint16Array(e.target.result)));
      } else {
        callback(e.target.result.toString());
      }
    }
    a.readAsDataURL(blob);
  }

  static dataURLtoBlob(dataurl: string): Blob {
    let arr = dataurl.split(',');
    let mime = /:(.*?);/.exec(arr[0])[1];
    let bstr = window.atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], {type: mime});
  }

  static toNumber(value: string | number, scale: number = 2): number {
    if (!value) {
      return null;
    }

    let stringValue = (value || '').toString().replace(/\D/g, "");
    if (stringValue.length > scale) {
      return Number(stringValue.substring(0, stringValue.length - scale) + '.' + stringValue.substring(stringValue.length - scale));
    }

    return Number(stringValue);
  }

  static removeMask(value: string | number): number {
    if (!value) {
      return null;
    }

    let stringValue = (value || '').toString().replace(/\D/g, "");
    return Number(stringValue);
  }

  static toFixed(value: number, scale: number = 2): any {
    if (!value) {
      return null;
    }

    return value.toFixed(scale);
  }

  static retiraAcento(palavra: string): string {
    return latinize(palavra);
  }

  static toggle(element: string): void {
    $(element).collapse('toggle');
  }

  static focus(element: HTMLElement | string): void {
    if (element instanceof HTMLElement) {
      element.focus();
      if (document.activeElement != element) {
        setTimeout(() => this.focus(element), 200);
      }
    } else {
      this.getElementForFocus(element);
    }
  }

  private static getElementForFocus(id: string): void {
    let element = document.getElementById(id);
    if (!element) {
      setTimeout(() => this.getElementForFocus(id), 100);
    } else {
      this.focus(element);
    }
  }

  static toUpperCase(valor: string) {
    return valor ? valor.toUpperCase() : "";
  }

  static mobile(callback: () => void): void {
    if (window.screen.width < 992 && callback) {
      callback();
    }
  }

  static isMobile(): boolean {
    return window.screen.width < 992;
  }

  static desktop(callback: () => void): void {
    if (window.screen.width >= 992 && callback) {
      callback();
    }
  }

  static scrollTo(top: number = 0): void {
    setTimeout(() => {
      scroll({
        top: top,
        left: 0,
        behavior: 'smooth'
      });
    }, 50);
  }

  static scrollToBottom(): void {
    setTimeout(() => {
      scroll({
        top: document.body.scrollHeight,
        left: 0,
        behavior: 'smooth'
      });
    }, 50);
  }
}
