import {Component} from '@angular/core';
import {Lang, LanguageService} from "../../services/language.service";
import {firstValueFrom, take} from "rxjs";
import {AutenticacaoService} from "../../services/autenticacao.service";
import {TokenStorageService} from "../../services/token-storage.service";

@Component({
  selector: 'app-idioma',
  templateUrl: './idioma.component.html',
  styleUrls: ['./idioma.component.scss']
})
export class IdiomaComponent {

  langs: Lang[];
  selected: Lang;
  lang: string;

  constructor(
    readonly languageService: LanguageService,
    private readonly tokenStorageService: TokenStorageService,
    private readonly autenticacaoService: AutenticacaoService
  ) {
    this.langs = languageService.availables();
    this.lang = languageService.lang;
    this.selected = languageService.selected

    languageService.languageChange.subscribe(lang => {
      this.lang = lang.lang.lang.toLowerCase();
      this.selected = lang.lang;
    });
  }

  path(lang: Lang): string {
    return `${location.pathname}?lang=${lang.lang}`
  }

  async updateLanguage(lang: string): Promise<void> {
    if (this.tokenStorageService.isAuthenticated()) {
      let user = await firstValueFrom(this.autenticacaoService.recuperarUsuario());
      let userEntity = user.entity;
      userEntity.idioma = lang;
      this.autenticacaoService.salvarDadosBasicosUsuario(userEntity).pipe(
        take(1)
      ).subscribe(() => {
        let user = this.tokenStorageService.getUser();
        user.idioma = lang;
        this.tokenStorageService.saveUser(user);
        window.location.href = `${location.pathname}?lang=${lang}`
      });
    } else {
      window.location.href = `${location.pathname}?lang=${lang}`;
    }
  }
}
