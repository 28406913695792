<nav aria-label="Page navigation" class="text-center" *ngIf="page?.collection?.length > 0">
  <ul class="pagination">
    <li>
      <a (click)="firstPage()" aria-label="First" [class.disabled]="isFirst()">
        <fa-icon [icon]="chevronLeft"></fa-icon>
        <fa-icon [icon]="chevronLeft"></fa-icon>
      </a>
    </li>
    <li>
      <a (click)="previousPage()" aria-label="Previous" [class.disabled]="isFirst()">
        <fa-icon [icon]="chevronLeft"></fa-icon>
      </a>
    </li>
    <li *ngFor="let pagina of paginas; let i = index; let first = first; let last = last" [class.active]="page.currentPage == pagina">
      <a *ngIf="first && pagina != 0">
        <fa-icon [icon]="ellipsis"></fa-icon>
      </a>
      <a (click)="paginacao(pagina)">
        {{pagina + 1}}
      </a>
      <a *ngIf="last && pagina < page.totalPages - 1">
        <fa-icon [icon]="ellipsis"></fa-icon>
      </a>
    </li>
    <li>
      <a (click)="nextPage()" aria-label="Next" [class.disabled]="isLast()">
        <fa-icon [icon]="chevronRight"></fa-icon>
      </a>
    </li>
    <li>
      <a (click)="lastPage()" aria-label="Last" [class.disabled]="isLast()">
        <fa-icon [icon]="chevronRight"></fa-icon>
        <fa-icon [icon]="chevronRight"></fa-icon>
      </a>
    </li>
  </ul>
</nav>
