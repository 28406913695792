import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[focus]'
})
export class FocusDirective implements AfterViewInit {

  @Input()
  preventScroll = true;

  constructor(
    private readonly elementRef: ElementRef
  ) {
  }

  ngAfterViewInit() {
    if (this.elementRef) {
      this.elementRef.nativeElement.focus({preventScroll: this.preventScroll});
      if (document.activeElement != this.elementRef.nativeElement) {
        setTimeout(() => this.ngAfterViewInit(), 200);
      }
    }
  }
}
