import {Component, OnInit} from '@angular/core';
import {UsuarioModel} from "../../shared/models/usuario.model";
import {DadosDominioService} from "../../shared/services/dados-dominio.service";
import {AutenticacaoService} from "../../shared/services/autenticacao.service";
import {TokenStorageService} from "../../shared/services/token-storage.service";
import {MessageService} from "../../shared/services/message.service";
import {Router} from "@angular/router";
import {Utils} from "../../shared/framework/utils";
import {take} from "rxjs";
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
  SocialUser
} from "@abacritt/angularx-social-login";
import {faLock, faUser} from "@fortawesome/free-solid-svg-icons";
import {faFacebookSquare} from "@fortawesome/free-brands-svg-icons";
import {LanguageService} from "../../shared/services/language.service";

declare const $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  user = faUser;
  lock = faLock;
  facebook = faFacebookSquare;

  emailSenha: string;
  usuario = new UsuarioModel();
  novoUsuario: UsuarioModel;
  bloquearBotaoSenha = false;
  loading = false;
  emailNaoConfirmado: string;

  constructor(
    private readonly autenticacaoService: AutenticacaoService,
    private readonly dadosDominioService: DadosDominioService,
    private readonly tokenStorageService: TokenStorageService,
    private readonly messageService: MessageService,
    private readonly router: Router,
    private readonly authService: SocialAuthService,
    readonly languageService: LanguageService
  ) {
    this.authService.authState.subscribe((user) => {
      if (user) {
        this.authService.signOut(true).catch(() => null);

        if (user.provider == GoogleLoginProvider.PROVIDER_ID) {
          this.onSignInGoogle(user);
        } else if (user.provider == FacebookLoginProvider.PROVIDER_ID) {
          this.onSignInFB(user);
        }
      }
    });
  }

  ngOnInit(): void {
    const url = this.router.url;

    if (!this.tokenStorageService.isAuthenticated() || url.indexOf('login') > 0 || url.indexOf('?') > 0) {
      // reset login status
      this.autenticacaoService.clearCredentials();
      this.bloquearBotaoSenha = false;

      if (url.indexOf('?') > 0 && url.indexOf('usr=') > 0) {
        let myParam = url.split('usr=')[1].split('&')[0];
        let myParam2 = url.split('pwd=')[1] ? url.split('pwd=')[1] : 'myDefaultValue';

        let usuario = new UsuarioModel();
        if (myParam2) {
          usuario.token = myParam2;
          usuario.login = myParam;
          usuario.tokenDetalharUsuario = myParam2;

          this.autenticacaoService.setCredentials(usuario);
          this.autenticacaoService.recuperarUsuario().pipe(
            take(1)
          ).subscribe(usuario => {
            this.tokenStorageService.saveUser(usuario.entity);
            this.checkLanguage(usuario.entity)
            this.router.navigate(['/geral/inicial']).then();
          });
        }
      }
    }
  }

  private checkLanguage(usuario: UsuarioModel): void {
    if (usuario?.idioma) {
      this.languageService.setLanguages(usuario.idioma);
    }
  }

  private onSignInGoogle(user: SocialUser): void {
    let usuario = {
      idGoogle: user.id,
      login: user.email,
      email: user.email,
      senha: user.idToken,
      nome: user.name
    };

    this.loading = true;
    this.autenticacaoService.loginGoogle(usuario).pipe(
      take(1)
    ).subscribe({
      next: response => {
        this.loading = false;
        if (response.sucesso) {
          TokenStorageService.setGlobals(response);
          this.autenticacaoService.clearCredentials();
          this.autenticacaoService.setCredentials(response.entity);
          this.checkLanguage(response.entity);
          this.router.navigate(['/geral/inicial']).then();
        } else {
          this.messageService.addDanger(response.msgs);
          this.autenticacaoService.clearCredentials();
        }
      },
      error: error => {
        this.loading = false;
        this.messageService.addDanger(error);
      }
    });
  }

  private onSignInFB(user: SocialUser): void {
    let usuario = {
      idGoogle: user.id,
      login: user.email,
      email: user.email,
      senha: user.authToken,
      nome: user.name
    };

    if (!usuario.email) {
      this.messageService.addWarning('Não foi possível recuperar o email, favor valide suas permissões no Facebook');
      return;
    }

    this.loading = true;
    this.autenticacaoService.loginFacebook(usuario).pipe(
      take(1)
    ).subscribe({
      next: response => {
        this.loading = false;
        if (response.sucesso) {
          TokenStorageService.setGlobals(response);
          this.autenticacaoService.clearCredentials();
          this.autenticacaoService.setCredentials(response.entity);
          this.checkLanguage(response.entity);
          this.router.navigate(['/geral/inicial']).then();
        } else {
          this.messageService.addDanger(response.msgs);
          this.autenticacaoService.clearCredentials();
        }
      },
      error: error => {
        this.loading = false;
        this.messageService.addDanger(error);
      }
    });
  }

  authUsuario(usuario: UsuarioModel, isPaginaInicial: boolean): void {
    this.loading = true;
    this.autenticacaoService.login(usuario).pipe(
      take(1)
    ).subscribe({
      next: response => {
        this.loading = false;
        if (response.sucesso) {
          this.autenticacaoService.clearCredentials();
          this.autenticacaoService.setCredentials(response.entity);
          this.checkLanguage(response.entity);

          if (isPaginaInicial) {
            if (this.tokenStorageService.isAdmin()) {
              this.router.navigate(['/admin/gerenciar/tudo']).then();
            } else {
              this.router.navigate(['/geral/inicial']).then();
            }
          } else {
            this.router.navigate(['/envios/cadastrados']).then();
          }
        } else if (response.msgs?.[0]?.mensagem === 'BO_USUARIO_NAO_CONFIRMADO') {
          this.emailNaoConfirmado = response.msgs[0].parametros[0];
          $('#modalEmailNaoConfirmado').modal('show');
        } else {
          this.messageService.addDanger(response.msgs);
          this.autenticacaoService.clearCredentials();
        }
      },
      error: error => {
        this.loading = false;
        this.messageService.addDanger(error);
      }
    });
  }

  autenticarUsuario(): void {
    this.authUsuario(this.usuario, true);
  }

  retirarCaracteresEspeciais(): void {
    if (this.novoUsuario) {
      this.novoUsuario.login = Utils.retiraAcento(this.novoUsuario.login);
    }

    this.usuario.login = Utils.retiraAcento(this.usuario.login);
  }

  recuperarSenha(): void {
    if (!this.emailSenha || this.emailSenha.trim().length == 0) {
      this.messageService.addWarning('main.login.campoEmailObrigatorio');
      return;
    }

    this.bloquearBotaoSenha = true;
    this.autenticacaoService.recuperarSenha({
      email: this.emailSenha.trim()
    }).pipe(
      take(1)
    ).subscribe({
      next: () => {
        this.emailSenha = '';
        $('#modalEsqueceuSenha').modal('hide');
        this.messageService.addSuccess('main.login.novaSenhaEnviadaEmail');
      },
      complete: () => this.bloquearBotaoSenha = false
    });
  }

  reenviarEmailNaoConfirmado(): void {
    this.autenticacaoService.reenviarEmailNaoConfirmado(this.emailNaoConfirmado).pipe(
      take(1)
    ).subscribe(() => {
      this.messageService.addSuccess('main.login.sucessoReenvioEmail');
      $('#modalEmailNaoConfirmado').modal('hide');
    });
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }
}
