import {catchError, EMPTY, map, Observable, pipe} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../environments/environment";
import {MessageService} from "../services/message.service";

/**
 * Base class for CRUD services.
 *
 * @typeParam T - the entity class type.
 * @param <K> - the ID class type.
 */
export abstract class ReadonlyService<T, K> {

  protected readonly baseURL;

  protected constructor(
    readonly url: string,
    protected readonly http: HttpClient,
    protected readonly messageService: MessageService
  ) {
    this.baseURL = environment.baseURL + url;
  }

  mapError = pipe(
    //This will get skipped if upstream throws an error
    map((v: any) => {
      if (typeof(v.sucesso) !== "undefined" && !v.sucesso) {
        throw v.msgs;
      }
      return v;
    }),
    catchError(error => {
      this.messageService.addDanger(error);
      throw error;
    })
  );

  find(id: K): Observable<T> {
    if (!id) {
      return EMPTY;
    }
    return this.http.get<T>(`${this.baseURL}/${id}`).pipe(
      this.mapError
    );
  }
}
