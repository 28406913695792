import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService} from "../framework/base.service";
import {UsuarioModel} from "../models/usuario.model";
import {TokenStorageService} from "./token-storage.service";
import {Observable} from "rxjs";
import {ContadorDto} from "../models/dto/contador.dto";
import {UsuarioDto} from "../models/dto/usuario.dto";
import {CampanhaModel} from "../models/campanha.model";
import {PesquisaUsuarioDto} from "../models/dto/pesquisa-usuario.dto";
import {CreditoDebitoUsuarioDto} from "../models/dto/credito-debito-usuario.dto";
import {ResumoEnvioUsuarioDto} from "../models/dto/resumo-envio-usuario.dto";
import {MessageService} from "./message.service";

@Injectable({
  providedIn: 'root'
})
export class AutenticacaoService extends BaseService<UsuarioDto, number> {

  constructor(
    readonly http: HttpClient,
    readonly messageService: MessageService,
    private readonly tokenStorageService: TokenStorageService
  ) {
    super('/loginRestService', http, messageService);
  }

  recuperarContadorUsuarios(): Observable<ContadorDto> {
    return this.http.post<ContadorDto>(`${this.baseURL}/recuperarContadorUsuarios`, {}).pipe(
      this.mapError
    );
  }

  login(usuario: UsuarioModel): Observable<UsuarioDto> {
    return this.http.post<UsuarioDto>(`${this.baseURL}/login`, usuario);
  }

  cadastrarEmail(email: string): Observable<void> {
    return this.http.post<void>(`${this.baseURL}/cadastrarEmail`, email);
  }

  tokenDetalharUsuario(usuario: UsuarioModel): Observable<UsuarioDto> {
    return this.http.post<UsuarioDto>(`${this.baseURL}/tokenDetalharUsuario`, usuario).pipe(
      this.mapError
    );
  }

  loginGoogle(usuario: UsuarioModel): Observable<UsuarioDto> {
    return this.http.post<UsuarioDto>(`${this.baseURL}/loginGoogle`, usuario);
  }

  loginFacebook(usuario: UsuarioModel): Observable<UsuarioDto> {
    return this.http.post<UsuarioDto>(`${this.baseURL}/loginFacebook`, usuario)
  }

  desabilitarCampanha(campanha: CampanhaModel): Observable<void> {
    return this.http.post<void>(`${this.baseURL}/desabilitarCampanha`, campanha).pipe(
      this.mapError
    );
  }

  unsubscribe(email: string): Observable<void> {
    return this.http.post<void>(`${this.baseURL}/unsubscribe`, email).pipe(
      this.mapError
    );
  }

  cadastrarUsuario(usuario: UsuarioModel): Observable<UsuarioDto> {
    return this.http.post<UsuarioDto>(`${this.baseURL}/cadastrarUsuario`, usuario).pipe(
      this.mapError
    );
  }

  recuperarSenha(usuario: UsuarioModel): Observable<UsuarioDto> {
    return this.http.post<UsuarioDto>(`${this.baseURL}/recuperarSenha`, usuario).pipe(
      this.mapError
    );
  }

  recuperarUsuario(): Observable<UsuarioDto> {
    return this.http.post<UsuarioDto>(`${this.baseURL}/recuperarUsuario`, {}).pipe(
      this.mapError
    );
  }

  recuperarAfiliadosUsuario(): Observable<UsuarioDto> {
    return this.http.post<UsuarioDto>(`${this.baseURL}/recuperarAfiliadosUsuario`, {}).pipe(
      this.mapError
    );
  }

  uploadFoto(arquivo: string): Observable<UsuarioDto> {
    return this.http.post<UsuarioDto>(`${this.baseURL}/uploadFoto`, arquivo).pipe(
      this.mapError
    );
  }

  salvarDadosBasicosUsuario(usuario: UsuarioModel): Observable<UsuarioDto> {
    return this.http.post<UsuarioDto>(`${this.baseURL}/salvarDadosBasicosUsuario`, usuario).pipe(
      this.mapError
    );
  }

  alterarSenha(usuario: UsuarioModel): Observable<UsuarioDto> {
    return this.http.post<UsuarioDto>(`${this.baseURL}/alterarSenhaUsuario`, usuario).pipe(
      this.mapError
    );
  }

  recuperarUsuarioPorTipoPesquisa(pesquisa: string, tipoPesquisa: string): Observable<UsuarioDto> {
    let pesquisaUsuarioDTO = new PesquisaUsuarioDto();
    pesquisaUsuarioDTO.pesquisa = pesquisa;
    pesquisaUsuarioDTO.tipoPesquisaEnum = tipoPesquisa;

    return this.http.post<UsuarioDto>(`${this.baseURL}/recuperarUsuarioPorTipoPesquisa`, pesquisaUsuarioDTO).pipe(
      this.mapError
    );
  }

  recuperarUsuarioPorTipoPesquisaCaixaUpload(pesquisa: string, tipoPesquisa: string): Observable<UsuarioDto> {
    let pesquisaUsuarioDTO: PesquisaUsuarioDto = {
      pesquisa,
      tipoPesquisaEnum: tipoPesquisa
    };

    return this.http.post<UsuarioDto>(`${this.baseURL}/recuperarUsuarioPorTipoPesquisaCaixaUpload`, pesquisaUsuarioDTO).pipe(
      this.mapError
    );
  }

  salvarPerfilUsuario(usuario: UsuarioModel): Observable<UsuarioDto> {
    return this.http.post<UsuarioDto>(`${this.baseURL}/salvarPerfilUsuario`, usuario).pipe(
      this.mapError
    );
  }

  recuperarCreditoDebitoUsuario(): Observable<CreditoDebitoUsuarioDto> {
    return this.http.post<CreditoDebitoUsuarioDto>(`${this.baseURL}/recuperarCreditoDebitoUsuario`, {}).pipe(
      this.mapError
    );
  }

  recuperarResumoEnvioUsuario(): Observable<ResumoEnvioUsuarioDto> {
    return this.http.post<ResumoEnvioUsuarioDto>(`${this.baseURL}/recuperarResumoEnvioUsuario`, {}).pipe(
      this.mapError
    );
  }

  confirmacaoCadastro(id: number, token: string): Observable<UsuarioDto> {
    return this.http.post<UsuarioDto>(`${this.baseURL}/confirmacaoCadastro/${id}`, token).pipe(
      this.mapError
    );
  }

  reenviarEmailNaoConfirmado(email: string): Observable<UsuarioDto> {
    return this.http.post<UsuarioDto>(`${this.baseURL}/reenviarEmailNaoConfirmado`, email).pipe(
      this.mapError
    );
  }

  setCredentials(usuario: UsuarioModel): void {
    usuario.dtLogin = new Date();
    this.tokenStorageService.saveToken(usuario.token);
    this.tokenStorageService.saveUser(usuario);
  }

  clearCredentials(): void {
    this.tokenStorageService.signOut();
  }
}
