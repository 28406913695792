<div *ngIf="src || foto360">
  <mv-slider
    *ngIf="!mobile$"
    class="slider-foto desktopm"
    [(value)]="sliderValue" [min]="40" [max]="100"
    (valueChange)="onSliderChange()"
  ></mv-slider>

  <div style="display: none">{{imageWidth}}</div>
  <div class="col-foto" *ngIf="src" #imageContainer>
    <img [style.width]="sliderValue + '%'"
         [alt]="alt" [src]="src"
         [class.statusProduto]="status != null"
         [attr.ok]="status"
         (touchmove)="moveFrameTouch($event)"
         (mousemove)="moveFrame($event)"
         (touchend)="mouseUp()"
         (touchcancel)="mouseUp()"
         (mouseout)="mouseUp()"
         (mouseup)="mouseUp()"
         (touchstart)="touchDown($event)"
         (mousedown)="mouseDown($event)"
         (dragstart)="$event.preventDefault()"
         [class.pointer]="image360"
    />

    <div class="col-lg-12 text-center loading" *ngIf="loadingImage">{{ 'system.loading' | translate }}</div>

    <div class="acoes-image" *ngIf="!loading && foto360">
      <div class="arrows">
        <fa-icon [icon]="arrowLeft"></fa-icon>
      </div>
      <div class="destaque">{{ 'system.foto360.texto' | translate }}</div>
      <div class="arrows">
        <fa-icon [icon]="arrowRight"></fa-icon>
      </div>
    </div>
  </div>
</div>
