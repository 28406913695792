import {ArquivoModel} from "./arquivo.model";
import {DebitoUsuarioModel} from "./debito-usuario.model";
import {AlertaUsuarioModel} from "./alerta-usuario.model";
import {CampanhaModel} from "./campanha.model";
import {CreditoUsuarioModel} from "./credito-usuario.model";

export class UsuarioModel {
  public idUsuario?: number;
  public qtdEnvio?: number;
  public idArquivo?: number;
  public usuarioIndicacaoCadastro?: number;
  public login?: string;
  public nome?: string;
  public sobrenome?: string;
  public email?: string;
  public emailConfirmacao?: string;
  public senha?: string;
  public tokenDetalharUsuario?: string;
  public senhaConfirmacao?: string;
  public senhaAntiga?: string;
  public telefonePrincipal?: string;
  public telefoneAlternativo?: string;
  public idGoogle?: string;
  public idFacebook?: string;
  public pesquisa?: string;
  public token?: string;
  public idioma?: string;
  public flgAceitarNotificacoes ?: boolean;
  public flgUsuarioBloqueado ?: boolean;
  public flgUsuarioBloqueadoPaypal ?: boolean;
  public flgSenha123?: boolean;
  public primeiroEnvio ?: boolean;
  public perfil?: string;
  public listaAlertaUsuario?: AlertaUsuarioModel[];
  public listaUsuarioAlertaUsuario?: AlertaUsuarioModel[];
  public listaCampanhaUsuario?: CampanhaModel;
  public listaCreditoUsuario?: CreditoUsuarioModel[];
  public listaDebitoUsuario?: DebitoUsuarioModel[];
  public listaUsuarioIndicado?: UsuarioModel[];
  public usuarioIndicacao?: UsuarioModel;
  public dtInclusao?: Date;
  public dtNascimento?: Date;
  public dtLogin?: Date;
  public arquivo?: ArquivoModel;
  public flgCadastroConfirmado?: boolean;
}
