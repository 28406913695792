<div>
  <div class="cadastro conteudo nao-autenticado">
    <div class="titulo-pagina">
      <div class="container-fluid">
        <h1>{{'main.menu.criar.conta' | translate}}</h1>
      </div>
    </div>

    <div class="corpo-nao-autenticado">
      <div class="container-fluid">
        <div class="col-lg-6 form-login center">
          <form name="formCadastro" (submit)="cadastrarUsuario()" role="form" #form="ngForm">
            <div class="col-lg-6 form-esq">
              <h2 class="subtitulo text-left">{{'main.login.cadastrar' | translate}}</h2>
              <fieldset class="form-group">
                <legend hidden="hidden">{{'main.login.cadastrar' | translate}}</legend>
                <div class="group-item">
                  <div>
                    <fa-icon [icon]="idCard"></fa-icon>
                  </div>
                  <input id="inputNome" class="form-control" type="text" focus
                         enterkeyhint="send" autocomplete="name"
                         [(ngModel)]="novoUsuario.nome" maxlength="150" name="inputNome"
                         [placeholder]="'main.login.nome' | translate" wRequired="true" noWhitespace
                         capitalize (keyup)="retirarCaracteresEspeciaisNome()"/>
                </div>
              </fieldset>
              <fieldset class="form-group">
                <legend hidden="hidden">{{'main.login.login' | translate}}</legend>
                <div class="group-item">
                  <div>
                    <fa-icon [icon]="idCard"></fa-icon>
                  </div>
                  <input class="form-control text-lowercase" type="text"
                         enterkeyhint="send" autocomplete="username" id="inputLogin"
                         [(ngModel)]="novoUsuario.login" maxlength="32" name="inputLogin"
                         [placeholder]="'main.login.login' | translate" wRequired="true" noWhitespace
                         capitalize (keyup)="retirarCaracteresEspeciais()"/>
                </div>
              </fieldset>
              <fieldset class="form-group">
                <legend hidden="hidden">{{'main.login.email' | translate}}</legend>
                <div class="group-item">
                  <div>
                    <fa-icon [icon]="envelope"></fa-icon>
                  </div>
                  <input id="inputEmail" class="form-control" type="email" email #e="ngModel"
                         enterkeyhint="send" autocomplete="email" inputmode="email"
                         maxlength="100" [(ngModel)]="novoUsuario.email" name="inputEmail"
                         [placeholder]="'main.login.email' | translate" wRequired="true" noWhitespace/>
                </div>
              </fieldset>
              <fieldset class="form-group">
                <legend hidden="hidden">{{'main.login.repitaEmail' | translate}}</legend>
                <div class="group-item">
                  <div>
                    <fa-icon [icon]="envelope"></fa-icon>
                  </div>
                  <input id="inputEmailConfirmacao" name="inputEmailConfirmacao"
                         enterkeyhint="send" autocomplete="off" inputmode="email"
                         class="form-control" type="email" email
                         [(ngModel)]="novoUsuario.emailConfirmacao"
                         [placeholder]="'main.login.repitaEmail' | translate"
                         wRequired="true" noWhitespace/>
                </div>
                <div class="msg-block" *ngIf="novoUsuario.email && !isEmailValido()">
									<span class="msg-error">
										{{'main.login.emailsNaoConferem' | translate}} </span>
                </div>
                <div class="msg-block" *ngIf="novoUsuario.email && e.errors?.['email']">
									<span class="msg-error">
										{{'main.login.emailInvalido' | translate}}
                  </span>
                </div>
              </fieldset>
              <fieldset class="form-group">
                <legend hidden="hidden">{{'main.login.campoSenha' | translate}}</legend>
                <div class="group-item">
                  <div>
                    <fa-icon [icon]="lock"></fa-icon>
                  </div>
                  <input id="inputSenha" class="form-control" type="password"
                         enterkeyhint="send" autocomplete="new-password"
                         [(ngModel)]="novoUsuario.senha" maxlength="12" name="inputSenha"
                         [placeholder]="'main.login.campoSenha' | translate" wRequired="true" noWhitespace/>
                </div>
              </fieldset>
              <fieldset class="form-group">
                <legend hidden="hidden">{{'main.login.repitaSenha' | translate}}</legend>
                <div class="group-item">
                  <div>
                    <fa-icon [icon]="lock"></fa-icon>
                  </div>
                  <input id="inputSenhaConfirmacao" name="inputSenhaConfirmacao"
                         enterkeyhint="send" autocomplete="new-password"
                         class="form-control" type="password" [(ngModel)]="novoUsuario.senhaConfirmacao"
                         [placeholder]="'main.login.repitaSenha' | translate"
                         wRequired="true" style="color: black; !important" noWhitespace/>
                </div>
                <div class="msg-block" *ngIf="novoUsuario.senha && !isSenhaValida()">
									<span class="msg-error">
										{{'main.login.senhasNaoConferem' | translate}}
                  </span>
                </div>
              </fieldset>
              <fieldset class="form-group text-left">
                <legend hidden="hidden">{{'main.login.receber.novidades' | translate}}</legend>
                <div class="checkbox i-checks">
                  <input id="receber-novidades" type="checkbox" [(ngModel)]="novoUsuario.flgAceitarNotificacoes"
                         name="receber-novidades" enterkeyhint="send">
                  <label for="receber-novidades">{{'main.login.receber.novidades' | translate}}</label>
                </div>
                <div class="checkbox i-checks">
                  <input id="termoaceito" type="checkbox" [(ngModel)]="termaccepted" [value]="true" name="termoaceito">
                  <label for="termoaceito">
                    {{'main.login.euConcordo' | translate}}
                    <a class="link-termos" data-toggle="modal" data-target="#modalTermo"
                       [title]="'main.login.termoDeUso' | translate">
                      {{'main.login.termoDeUso' | translate}}
                    </a>
                  </label>
                </div>
              </fieldset>

              <div class="col-lg-12">
                <div class="row">
                  <button type="submit" class="btn btn-laranja"
                          [disabled]="form.invalid || !isFormValido() || !termaccepted"
                          enterkeyhint="send">
                    {{'main.login.cadastrese.titulo' | translate}}
                  </button>
                </div>
              </div>
            </div>
            <div class="texto-login-ou">
              {{'main.login.ou' | translate}}
            </div>
            <div class="col-lg-6 form-dir" id="fb-rootCadastro">
              <div id="customBtnLogin" class="group-btn-google">
                <asl-google-signin-button
                  type="standard"
                  size="large"
                  shape="rectangular"
                  logo_alignment="left"
                ></asl-google-signin-button>
              </div>
              <div class="group-btn-face">
                <button type="button" class="btn btn-login-face" (click)="signInWithFB()">
                  <fa-icon [icon]="facebook" class="logofb"></fa-icon>
                  {{'main.login.entrar.face' | translate}}
                </button>
              </div>
            </div>
            <div class="col-lg-12 form-baixo">
              <h3 class="chamada">{{'main.login.jaTemConta' | translate}}</h3>
              <button routerLink="/login" class="btn btn-laranja-borda">
                {{'main.login.entrar.titulo' | translate}}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modalTermo" role="dialog">

  <div class="modal-dialog modal-lg">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <div class="titulo-modal">
          {{'main.login.termoDeUso' | translate}}
        </div>
        <div class="close-modal">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
      </div>

      <div class="modal-body">
        <app-termos termosId="termosCadastro"></app-termos>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{'global.fechar' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal Email não confirmado-->
<div class="modal fade" id="modalEmailNaoConfirmado" role="dialog">
  <div class="modal-dialog modal-lg-6">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <div class="titulo-modal">{{'main.login.emailsNaoConfirmado' | translate}}</div>
      </div>
      <div class="modal-body">
        <label for="inputEmail" class="enviolabel">
          {{'BO_USUARIO_NAO_CONFIRMADO' | translate}} {{emailNaoConfirmado}}
        </label>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{'global.fechar' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="reenviarEmailNaoConfirmado()">
          {{'main.login.reenvioEmailCadastro' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
