import {Component} from '@angular/core';
import {AutenticacaoService} from "../../../shared/services/autenticacao.service";
import {MessageService} from "../../../shared/services/message.service";
import {TokenStorageService} from "../../../shared/services/token-storage.service";
import {take} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {UsuarioModel} from "../../../shared/models/usuario.model";
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
  SocialUser
} from "@abacritt/angularx-social-login";
import {faEnvelope, faIdCard, faLock} from "@fortawesome/free-solid-svg-icons";
import {faFacebookSquare} from "@fortawesome/free-brands-svg-icons";
import {LanguageService} from "../../../shared/services/language.service";

declare const $: any;

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.scss']
})
export class CadastroComponent {

  idCard = faIdCard;
  envelope = faEnvelope;
  lock = faLock;
  facebook = faFacebookSquare;

  novoUsuario = new UsuarioModel();
  termaccepted: boolean = false;

  emailNaoConfirmado: string;

  constructor(
    readonly languageService: LanguageService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly messageService: MessageService,
    private readonly autenticacaoService: AutenticacaoService,
    private readonly tokenStorageService: TokenStorageService,
    private readonly authService: SocialAuthService
  ) {
    const afiliado = this.activatedRoute.snapshot.params["afiliado"];
    if (afiliado) {
      this.tokenStorageService.usuarioIndicacao = Number(afiliado);
    }

    this.authService.authState.subscribe(user => {
      if (user) {
        this.authService.signOut(true).catch(() => null);

        if (user.provider == GoogleLoginProvider.PROVIDER_ID) {
          this.onSignInGoogle(user);
        } else if (user.provider == FacebookLoginProvider.PROVIDER_ID) {
          this.onSignInFB(user);
        }
      }
    });
  }

  cadastrarUsuario(): void {
    let usuario = this.novoUsuario;
    let usuarioIndicacao = this.tokenStorageService.usuarioIndicacao;
    if (usuarioIndicacao) {
      usuario.usuarioIndicacaoCadastro = usuarioIndicacao;
    }

    this.autenticacaoService.cadastrarUsuario(usuario).pipe(
      take(1)
    ).subscribe(response => {
      if (response.sucesso) {
        this.autenticacaoService.clearCredentials();
        this.messageService.addSuccess('Conta cadastrada com sucesso.');
        this.router.navigate(['/login']).then();
      } else if (response.msgs[0].mensagem && response.msgs[0].mensagem === 'BO_USUARIO_NAO_CONFIRMADO') {
        this.emailNaoConfirmado = response.msgs[0].parametros[0];
        $('#modalEmailNaoConfirmado').modal('show');
      } else {
        this.messageService.addDanger(response.msgs);
        this.autenticacaoService.clearCredentials();
      }
    });
  }

  reenviarEmailNaoConfirmado(): void {

    this.autenticacaoService.reenviarEmailNaoConfirmado(this.emailNaoConfirmado).pipe(
      take(1)
    ).subscribe(() => {
      this.messageService.addSuccess('main.login.sucessoReenvioEmail');
      $('#modalEmailNaoConfirmado').modal('hide');
    });
  }

  retiraAcento(palavra: string): string {
    let com_acento = 'áàãâäéèêëíìîïóòõôöúùûüçÁÀÃÂÄÉÈÊËÍÌÎÏÓÒÕÖÔÚÙÛÜÇ´`^¨~';
    let sem_acento = 'aaaaaeeeeiiiiooooouuuucAAAAAEEEEIIIIOOOOOUUUUC     ';
    for (let l of palavra) {
      for (let l2 of com_acento) {
        if (palavra[l] == com_acento[l2]) {
          palavra = palavra.replace(palavra[l], sem_acento[l2]);
        }
      }
    }

    return palavra;
  }

  retirarCaracteresEspeciais(): void {
    if (this.novoUsuario) {
      this.novoUsuario.login = this.retiraAcento(this.novoUsuario.login);
    }
  }

  retirarCaracteresEspeciaisNome(): void {
    if (this.novoUsuario != undefined) {
      this.novoUsuario.nome = this.retiraAcento(this.novoUsuario.nome);
    }
  }


  private onSignInGoogle(user: SocialUser): void {
    let usuario = {
      idGoogle: user.id,
      login: user.email,
      email: user.email,
      senha: user.idToken,
      nome: user.name
    };

    this.autenticacaoService.loginGoogle(usuario).pipe(
      take(1)
    ).subscribe({
      next: response => {
        if (response.sucesso) {
          TokenStorageService.setGlobals(response);
          this.autenticacaoService.clearCredentials();
          this.autenticacaoService.setCredentials(response.entity);
          this.router.navigate(['/geral/inicial']).then();
        } else {
          this.messageService.addDanger(response.msgs);
          this.autenticacaoService.clearCredentials();
        }
      },
      error: error => this.messageService.addDanger(error)
    });
  }

  private onSignInFB(user: SocialUser): void {
    let usuario = {
      idGoogle: user.id,
      login: user.email,
      email: user.email,
      senha: user.authToken,
      nome: user.name
    };

    if (!usuario.email) {
      this.messageService.addWarning('Não foi possível recuperar o email, favor valide suas permissões no Facebook');
      return;
    }

    this.autenticacaoService.loginFacebook(usuario).pipe(
      take(1)
    ).subscribe({
      next: response => {
        if (response.sucesso) {
          TokenStorageService.setGlobals(response);
          this.autenticacaoService.clearCredentials();
          this.autenticacaoService.setCredentials(response.entity);
          this.router.navigate(['/geral/inicial']).then();
        } else {
          this.messageService.addDanger(response.msgs);
          this.autenticacaoService.clearCredentials();
        }
      },
      error: error => this.messageService.addDanger(error)
    });
  }

  isEmailValido(): boolean {
    return this.novoUsuario.email === this.novoUsuario.emailConfirmacao;
  }

  isSenhaValida(): boolean {
    return this.novoUsuario.senha === this.novoUsuario.senhaConfirmacao;
  }

  isFormValido(): boolean {
    return this.isEmailValido() && this.isSenhaValida();
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }
}
