<footer class="footer-interna">
  <div class="footer2">
    <div class="col-lg-4 texto-copyright">
      <strong>{{ ('system.direitosAutorais' | translate).replaceAll('{data}', data | date: 'yyyy') }}</strong><span
      class="ds">&#8482;</span>
      <span class="direitos">{{ 'inicial.direitos' | translate }}</span>
      <a routerLink="/geral/politica-privacidade">{{ 'main.login.termoDeUso' | translate }}</a>
    </div>
    <div class="col-lg-4 contatos">
      <a href="https://api.whatsapp.com/send?l=pt_BR&phone=13215227341" target="_blank" class="btn-whatsapp"
         title="WhatsApp" rel="noopener">
        <fa-icon [icon]="whatsapp"></fa-icon>
        +1 (321) 522-7341
      </a>
      <a href="mailto:{{contactEmail}}" class="btn-email" placement="top" tooltip="E-mail">
        <fa-icon [icon]="envelopeOpenText"></fa-icon>
        <span> {{ contactEmail }}</span>
      </a>
    </div>
    <div class="col-lg-4">
      <ul class="redes-sociais">
        <li>
          <a href="https://www.facebook.com/ViajaBox-260491780961484/" target="_blank" class="btn-face"
             title="Facebook" rel="noopener">
            <fa-icon [icon]="facebook"></fa-icon>
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/viaja_box/" target="_blank" class="btn-instagram"
             title="Instagram" rel="noopener">
            <fa-icon [icon]="instagram"></fa-icon>
          </a>
        </li>
        <li>
          <a href="https://twitter.com/ViajaBox" target="_blank" class="btn-twitter" title="Twitter" rel="noopener">
            <fa-icon [icon]="twitter"></fa-icon>
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/channel/UCPXSI-jfj5gww3-qSaN2ztQ" target="_blank" class="btn-youtube"
             title="Youtube" rel="noopener">
            <fa-icon [icon]="youtube"></fa-icon>
          </a>
        </li>
        <li>
          <div class="v">{{ VERSION }}</div>
        </li>
      </ul>
    </div>
  </div>
  <a href="https://api.whatsapp.com/send?l=pt_BR&phone=13215227341" target="_blank" class="btn-whatsapp-flutuante"
     placement="top" tooltip="WhatsApp" rel="noopener" *ngIf="!admin">
    <fa-icon [icon]="whatsapp"></fa-icon>
  </a>
  <div (click)="scrollToTop()" (keydown)="scrollToTop()" class="btn-topo" *ngIf="!admin">
    <fa-icon [icon]="chevronUp"></fa-icon>
  </div>
</footer>
