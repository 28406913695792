<div class="choose-languages">
  <ul>
    <li class="dropdown bandeiras">
      <a class="dropdown-toggle" data-toggle="dropdown" href="#" aria-expanded="false"
         [title]="'system.idioma' | translate">
        <img [src]="selected?.flag" [alt]="selected?.alt || selected?.name" loading="eager"
             [title]="selected?.alt || selected?.name" width="26" height="16">
        <span class="lang">{{ selected?.name }}</span>
        <span class="lang-abrev">{{ selected?.code }}</span>
        <span class="caret"></span>
      </a>
      <ul class="dropdown-menu dropdown-limited dropdown-menu-right">
        <li *ngFor="let l of langs">
          <a [title]="'system.idioma' | translate" rel="nofollow" (click)="updateLanguage(l.lang)">
            <img [src]="l.flag" [alt]="l.alt || l.name" [title]="l.alt || l.name" width="26" height="16"
                 loading="lazy">
            <span class="lang">{{ l.name }}</span>
            <span class="lang-abrev">{{ l.code }}</span>
          </a>
        </li>
      </ul>
    </li>
  </ul>
</div>
