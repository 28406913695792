<div class="inicio conteudo nao-autenticado">
  <div class="corpo-nao-autenticado">
    <div class="box-d">
      <div class="container-fluid">
        <app-carousel></app-carousel>
      </div>
    </div>
    <div class="ie">
      <div class="container-fluid">
        <div class="col-lg-12 corpo-ie">
          <div class="col-lg-3">
            <div class="caixa-etapas">
							<span class="icone-etapas">
                <fa-icon [icon]="clipboardList"></fa-icon>
							</span>
              <span class="titulo-etapas">
								{{ 'inicio.titulo.etapas1' | translate }}
							</span>
              <span class="desc-etapas">
								{{ 'inicio.desc.etapas1' | translate }}
							</span>
            </div>
            <fa-icon [icon]="arrowRight" class="setap"></fa-icon>
          </div>
          <div class="col-lg-3">
            <div class="caixa-etapas">
							<span class="icone-etapas">
                <fa-icon [icon]="shoppingCart"></fa-icon>
							</span>
              <span class="titulo-etapas">
								{{ 'inicio.titulo.etapas2' | translate }}
							</span>
              <span class="desc-etapas">
								{{ 'inicio.desc.etapas2' | translate }}
							</span>
            </div>
            <fa-icon [icon]="arrowRight" class="setap"></fa-icon>
          </div>
          <div class="col-lg-3">
            <div class="caixa-etapas">
							<span class="icone-etapas">
                <fa-icon [icon]="tasks"></fa-icon>
							</span>
              <span class="titulo-etapas">
								{{ 'inicio.titulo.etapas3' | translate }}
							</span>
              <span class="desc-etapas">
								{{ 'inicio.desc.etapas3' | translate }}
							</span>
            </div>
            <fa-icon [icon]="arrowRight" class="setap"></fa-icon>
          </div>
          <div class="col-lg-3">
            <div class="caixa-etapas">
							<span class="icone-etapas">
                <fa-icon [icon]="parachuteBox"></fa-icon>
							</span>
              <span class="titulo-etapas">
								{{ 'inicio.titulo.etapas4' | translate }}
							</span>
              <span class="desc-etapas">
								{{ 'inicio.desc.etapas4' | translate }}
							</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="box-vantagens">
      <div class="container-fluid">
        <div class="box-tv">
          <p class="chamada titulo-vantagens">{{ 'inicio.vantagens.tit' | translate }}</p>
        </div>
        <div class="box-texto-vantagens">
          <ul>
            <li>{{ 'inicio.vantagens.text1' | translate }}</li>
            <li>{{ 'inicio.vantagens.text2' | translate }}</li>
            <li>{{ 'inicio.vantagens.text3' | translate }}</li>
            <li>{{ 'inicio.vantagens.text4' | translate }}</li>
            <li>{{ 'inicio.vantagens.text5' | translate }}</li>
            <li>{{ 'inicio.vantagens.text6' | translate }}</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="compra-viajabox">
      <div class="box-i">
        <div class="container-fluid">
          <div class="col-lg-7">
            <div class="inf">
              <div class="card card-credito">
                <div class="icone-card">
                  <fa-icon [icon]="creditCard"></fa-icon>
                </div>
                <div class="texto-card">
                  {{ 'inicio.compra.viajabox.infografico.tit1' | translate }}
                </div>
              </div>
              <span class="texto-ou-1">{{ 'main.login.ou' | translate }}</span>
              <div class="card card-ingles">
                <div class="icone-card">
                  <fa-icon [icon]="questionCircle"></fa-icon>
                </div>
                <div class="texto-card">
                  {{ 'inicio.compra.viajabox.infografico.tit2' | translate }}
                </div>
              </div>
              <span class="texto-ou-2">{{ 'main.login.ou' | translate }}</span>
              <div class="card card-sites">
                <div class="icone-card">
                  <fa-icon [icon]="flagUsa"></fa-icon>
                </div>
                <div class="texto-card">
                  {{ 'inicio.compra.viajabox.infografico.tit3' | translate }}
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="row">
                <span class="chamada1-compra-viajabox">{{ 'inicio.compra.viajabox.text1' | translate }}</span>
                <span class="chamada2-compra-viajabox">{{ 'inicio.compra.viajabox.text2' | translate }}</span>
                <a routerLink="/compra-viajabox" [title]="'inicio.compra.viajabox.text3' | translate"
                   class="btn btn-azul-borda btn-white">
                  {{ 'inicio.compra.viajabox.text3' | translate }}
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-5 box-atendente">
            <img ngSrc="/assets/img/atendente-assistida.webp" width="640" height="556" class="atendente-assistida"
                 loading="lazy" [alt]="'inicio.compra.viajabox.atendente' | translate">
          </div>
        </div>
        <div class="bg-mesa"></div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="dc">
        <div class="col-lg-12">
          <div class="chamada">{{ 'inicio.depoimentos.tit' | translate }}</div>
        </div>
        <div class="col-lg-4">
          <div class="depoimento">
						<span class="texto-depoimento">
							"{{ 'inicio.depoimentos.dep1' | translate }}"
						</span>
            <span class="autor-depoimento">Jose Davi</span>
            <div class="pais-depoimento">
              <span>
                <img ngSrc="assets/flags/br.svg" class="bandeiras" alt="Bandeira do Brasil" width="27" height="20"
                     loading="lazy">
              </span>
              <span class="pais">Rio grande do Sul, {{ 'inicio.depoimentos.brasil' | translate }}</span>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="depoimento">
						<span class="texto-depoimento">
							"{{ 'inicio.depoimentos.dep2' | translate }}"
						</span>
            <span class="autor-depoimento">Eliana Santos</span>
            <div class="pais-depoimento">
              <span>
                <img ngSrc="assets/flags/br.svg" class="bandeiras" alt="Bandeira do Brasil" width="27" height="20"
                     loading="lazy">
              </span>
              <span class="pais">Rio de Janeiro, {{ 'inicio.depoimentos.brasil' | translate }}</span>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="depoimento">
						<span class="texto-depoimento">
							"{{ 'inicio.depoimentos.dep3' | translate }}"
						</span>
            <span class="autor-depoimento">Alice Beatriz</span>
            <div class="pais-depoimento">
              <span>
                <img ngSrc="assets/flags/br.svg" class="bandeiras" alt="Bandeira do Brasil" width="27" height="20"
                     loading="lazy">
              </span>
              <span class="pais">Distrito Federal, {{ 'inicio.depoimentos.brasil' | translate }}</span>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="depoimento">
						<span class="texto-depoimento">
							"{{ 'inicio.depoimentos.dep4' | translate }}"
						</span>
            <span class="autor-depoimento">Bianca Silva</span>
            <div class="pais-depoimento">
              <span>
                <img ngSrc="assets/flags/br.svg" class="bandeiras" alt="Bandeira do Brasil" width="27" height="20"
                     loading="lazy">
              </span>
              <span class="pais">Amazonas, {{ 'inicio.depoimentos.brasil' | translate }}</span>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="depoimento">
						<span class="texto-depoimento">
							"{{ 'inicio.depoimentos.dep5' | translate }}"
						</span>
            <span class="autor-depoimento">Luiza</span>
            <div class="pais-depoimento">
              <span>
                <img ngSrc="assets/flags/br.svg" class="bandeiras" alt="Bandeira do Brasil" width="27" height="20"
                     loading="lazy">
              </span>
              <span class="pais">Minas Gerais, {{ 'inicio.depoimentos.brasil' | translate }}</span>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="depoimento">
						<span class="texto-depoimento">
							"{{ 'inicio.depoimentos.dep6' | translate }}"
						</span>
            <span class="autor-depoimento">Alezandro Cresci</span>
            <div class="pais-depoimento">
              <span>
                <img ngSrc="assets/flags/br.svg" class="bandeiras" alt="Bandeira do Brasil" width="27" height="20"
                     loading="lazy">
              </span>
              <span class="pais">Pernambuco, {{ 'inicio.depoimentos.brasil' | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="box-qs">
      <div class="container-fluid">
        <div class="col-lg-7">
          <div class="chamada">{{ 'inicio.box.quem.somos.tit' | translate }}</div>
          <p>{{ 'inicio.box.quem.somos.text1' | translate }}
            <strong>{{ 'inicio.box.quem.somos.text2' | translate }}</strong>, {{ 'inicio.box.quem.somos.text3' | translate }}
            <strong>{{ 'inicio.box.quem.somos.text4' | translate }}</strong> {{ 'inicio.box.quem.somos.text5' | translate }}
            <strong>{{ 'inicio.box.quem.somos.text6' | translate }}</strong>.
          </p>
          <p>{{ 'inicio.box.quem.somos.text7' | translate }}</p>
          <p>{{ 'inicio.box.quem.somos.text8' | translate }}</p>
          <p><strong>{{ 'inicio.box.quem.somos.text9' | translate }}</strong></p>
        </div>
        <div class="col-lg-5">
          <div class="icone-balao">
            <fa-icon [icon]="parachuteBox"></fa-icon>
          </div>
        </div>
      </div>
    </div>

    <div class="box-redes-sociais">
      <div class="container-fluid">
        <div class="col-lg-12">
          <div class="chamada">{{ 'inicio.box.redes.tit' | translate }}</div>
        </div>
        <div class="col-lg-12">
          <ul class="redes-sociais">
            <li>
              <a href="https://www.facebook.com/ViajaBox-260491780961484/" target="_blank" class="btn-face"
                 title="Facebook" aria-label="Facebook" rel="noopener">
                <fa-icon [icon]="facebook" class="facebook"></fa-icon>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/viaja_box/" target="_blank" class="btn-instagram" title="Instagram"
                 aria-label="Instagram" rel="noopener">
                <img ngSrc="assets/img/instagram.svg" width="70" height="70" alt="Instagram" class="instagram">
              </a>
            </li>
            <li>
              <a href="https://twitter.com/ViajaBox" target="_blank" class="btn-twitter" title="Twitter"
                 aria-label="Twitter" rel="noopener">
                <fa-icon [icon]="twitter" class="twitter"></fa-icon>
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCPXSI-jfj5gww3-qSaN2ztQ" target="_blank" class="btn-youtube"
                 title="Youtube" aria-label="Youtube" rel="noopener">
                <fa-icon [icon]="youtube" class="youtube"></fa-icon>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="box-verde">
      <div class="container-fluid">
        <div class="col-lg-12">
          <div class="titulo1-box-azul">{{ 'inicio.box.verde.text' | translate }}</div>
          <a routerLink="/cadastro" class="btn btn-azul" [title]="'inicio.compra.viajabox.btn.cadastro' | translate">
            {{ 'inicio.compra.viajabox.btn.cadastro' | translate }}
          </a>
        </div>
      </div>
    </div>

    <div class="box-formas-pagamento">
      <div class="container-fluid">
        <div class="col-lg-12">
          <div class="chamada">{{ 'inicio.box.pagamento.tit' | translate }}</div>
        </div>
        <div class="corpo-formas-pagamento">
          <div class="caixa-formas-pagamento">
            <img ngSrc="assets/img/formas-pagamento.webp" [alt]="'inicio.box.pagamento.tit' | translate" width="980"
                 height="50" loading="lazy">
          </div>
        </div>
      </div>
    </div>

    <div class="ajuda">
      <div class="container-fluid">
        <div class="col-md-12 texto-mais-ajuda">
          <div class="chamada">{{ 'inicio.maisduvidas1' | translate }}</div>
          <p>{{ 'menu.ajuda.maisduvidas2' | translate }}</p>
        </div>
        <div class="col-md-12 icones-contato">
          <div class="col-md-4 ajuda-whatsapp">
            <fa-icon [icon]="whatsapp"></fa-icon>
            <a href="https://api.whatsapp.com/send?l=pt_BR&phone=13215227341" target="_blank"
               rel="noopener" title="WhatsApp">+1 (321) 522-7341</a>
          </div>
          <div class="col-md-4 ajuda-telefone">
            <fa-icon [icon]="questionCircle"></fa-icon>
            <a routerLink="/central-ajuda" [title]="'inicio.perguntas.frequentes' | translate">
              {{ 'inicio.perguntas.frequentes' | translate }}
            </a>
          </div>
          <div class="col-md-4 ajuda-email">
            <fa-icon [icon]="envelopeOpenText"></fa-icon>
            <a href="mailto:{{contactEmail}}" style="word-break: break-all;"
               [title]="contactEmail">{{ contactEmail }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="cadastroEmail">
  <div class="modal-dialog modal-lg-6">
    <form #formEmail="ngForm">
      <div class="modal-content">
        <div class="modal-header">
          <div class="titulo-modal">{{ 'inicio.modal.email.titulo' | translate }}</div>
          <div class="close-modal">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
          </div>
        </div>
        <div class="modal-body">
          <div class="col-md-12 m-b-1">
            {{ 'inicio.modal.email.texto' | translate }}
          </div>
          <div class="col-md-6 col-md-offset-3">
            <div class="form-group">
              <div class="group-item">
                <label for="inputEmail">
                  <fa-icon [icon]="envelope"></fa-icon>
                </label>
                <input id="inputEmail" class="form-control" type="email" email
                       enterkeyhint="send" autocomplete="email" inputmode="email"
                       maxlength="100" [(ngModel)]="email" name="inputEmail"
                       [placeholder]="'main.login.email' | translate" wRequired="true" noWhitespace/>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            {{ 'global.voltar' | translate }}
          </button>
          <button id="idBotaoCadastroEmail" class="btn btn-principal" type="button"
                  [disabled]="formEmail.invalid" (click)="cadastrarEmail()">
            <span>{{ 'inicio.modal.email.cadastro' | translate }}</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
