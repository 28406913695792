import {HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, EMPTY, Observable, throwError} from 'rxjs';
import {TokenStorageService} from "../services/token-storage.service";
import {Router} from "@angular/router";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private readonly router: Router,
    private readonly tokenStorageService: TokenStorageService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req;
    const token = this.tokenStorageService.getToken();
    if (authReq.url.indexOf('https://viacep.com.br/') !== -1) {
      // Do not send credentials to ViaCEP.
    } else if (token != null) {
      authReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + token)
      });
    }
    return next.handle(authReq).pipe(
      catchError((error, caught) => {
        if (error && (error.status === 401 || error.status === 403) && !error.url.endsWith('/api/auth/sign-in')) {
          this.tokenStorageService.signOut();
          this.router.navigate(['/login']).then();
          return EMPTY;
        }

        return throwError(() => error);
      })
    );
  }
}

export const authInterceptorProviders = [
  {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
];
