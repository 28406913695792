<div class="topo-index">
  <div class="linha-topo">
    <div class="container-fluid">
      <div class="contato">
        <a href="https://api.whatsapp.com/send?l=pt_BR&phone=13215227341" target="_blank" class="btn-whatsapp"
           title="WhatsApp" aria-label="WhatsApp" rel="noopener">
          <span style="display: inline-block; width: 12px">
            <fa-icon [icon]="whatsapp"></fa-icon>
          </span> +1 (321) 522-7341
        </a>
      </div>
      <ul class="redes-sociais">
        <li>
          <a href="https://www.facebook.com/ViajaBox-260491780961484/" target="_blank" class="btn-face"
             title="Facebook" aria-label="Facebook" rel="noopener" style="width: 24px; display: inline-block">
            <fa-icon [icon]="facebook"></fa-icon>
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/viaja_box/" target="_blank" class="btn-instagram" title="Instagram"
             aria-label="Instagram" rel="noopener" style="width: 28px; display: inline-block">
            <fa-icon [icon]="instagram"></fa-icon>
          </a>
        </li>
        <li>
          <a href="https://twitter.com/ViajaBox" target="_blank" class="btn-twitter" title="Twitter"
             aria-label="Twitter" rel="noopener" style="width: 30px; display: inline-block">
            <fa-icon [icon]="twitter"></fa-icon>
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/channel/UCPXSI-jfj5gww3-qSaN2ztQ" target="_blank" class="btn-youtube"
             title="Youtube" aria-label="Youtube" rel="noopener" style="width: 32px; display: inline-block">
            <fa-icon [icon]="youtube"></fa-icon>
          </a>
        </li>
      </ul>
      <app-idioma class="cl"></app-idioma>
    </div>
  </div>

  <nav class="navbar navbar-default nav-principal">
    <div class="container-fluid">
      <div class="navbar-header">
        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
                data-target="#menu-nao-autenticado" aria-expanded="false">
          <span class="sr-only">{{ 'system.toggleMenu' | translate }}</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <a class="navbar-brand" routerLink="/"
           (click)="hide()" [title]="'system.logomarca' | translate">
          <img ngSrc="assets/img/logo-viajabox.webp" [alt]="'system.logomarca' | translate"
               [title]="'system.logomarca' | translate" height="50" width="157">
        </a>
      </div>
      <div class="collapse navbar-collapse" id="menu-nao-autenticado"
           itemscope itemtype="https://schema.org/BreadcrumbList">
        <form class="navbar-form navbar-right bc">
          <span itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
            <a routerLink="/login" class="btn btn-laranja-borda" [title]="'main.menu.entrar' | translate"
               itemprop="item">
              <span itemprop="name">{{ 'main.menu.entrar' | translate }}</span>
            </a>
            <meta itemprop="position" content="10"/>
          </span>
          <span itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
            <a routerLink="/cadastro" class="btn btn-laranja" [title]="'main.menu.criar.conta' | translate"
               itemprop="item">
              <span itemprop="name">{{ 'main.menu.criar.conta' | translate }}</span>
            </a>
            <meta itemprop="position" content="11"/>
          </span>
        </form>
        <ul class="nav navbar-nav normal">
          <li routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
              itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
            <a routerLink="/" aria-current="page" [title]="'main.menu.inicio' | translate" itemprop="item"
               (click)="hide()">
              <span itemprop="name">{{ 'main.menu.inicio' | translate }}</span>
            </a>
            <meta itemprop="position" content="1"/>
          </li>
          <li routerLink="/quem-somos" routerLinkActive="active"
              itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
            <a routerLink="/quem-somos" [title]="'main.menu.quem.somos' | translate" itemprop="item"
               (click)="hide()">
              <span itemprop="name">{{ 'main.menu.quem.somos' | translate }}</span>
            </a>
            <meta itemprop="position" content="2"/>
          </li>
          <li routerLink="/redirecionamento" routerLinkActive="active"
              itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
            <a routerLink="/redirecionamento" [title]="'main.menu.redirecionamento' | translate" itemprop="item"
               (click)="hide()">
              <span itemprop="name">{{ 'main.menu.redirecionamento' | translate }}</span>
            </a>
            <meta itemprop="position" content="3"/>
          </li>
          <li routerLink="/compra-viajabox" routerLinkActive="active"
              itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
            <a routerLink="/compra-viajabox" [title]="'main.menu.compra.viajabox' | translate" itemprop="item"
               (click)="hide()">
              <span itemprop="name">{{ 'main.menu.compra.viajabox' | translate }}</span>
            </a>
            <meta itemprop="position" content="4"/>
          </li>
          <li routerLink="/vantagens" routerLinkActive="active"
              itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
            <a routerLink="/vantagens" [title]="'main.menu.vantagens' | translate" itemprop="item"
               (click)="hide()">
              <span itemprop="name">{{ 'main.menu.vantagens' | translate }}</span>
            </a>
            <meta itemprop="position" content="5"/>
          </li>
          <li routerLink="/lojas-sugeridas" routerLinkActive="active"
              itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
            <a routerLink="/lojas-sugeridas" [title]="'main.menu.lojas' | translate" itemprop="item"
               (click)="hide()">
              <span itemprop="name">{{ 'main.menu.lojas' | translate }}</span>
            </a>
            <meta itemprop="position" content="6"/>
          </li>
          <li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
            <a href="/blog" [title]="'main.menu.blog' | translate" target="_blank" itemprop="item"
               (click)="hide()">
              <span itemprop="name">{{ 'main.menu.blog' | translate }}</span>
            </a>
            <meta itemprop="position" content="7"/>
          </li>
          <li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem" class="menu">
            <div class="pointer menu-pai" data-toggle="collapse" data-target="#ferramentas" aria-expanded="false"
                 [class.active]="routerSimulador.isActive || routerCalculadora.isActive || routerExemplo.isActive"
                 (mouseleave)="hide()" (mouseenter)="show('#ferramentas')">
              <span itemprop="name" class="name">{{ 'main.menu.ferramentas' | translate }}</span>

              <div class="submenu collapse" id="ferramentas" aria-expanded="false">
                <li routerLink="/calculadora-frete" routerLinkActive="active" #routerSimulador="routerLinkActive"
                    itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
                  <a routerLink="/calculadora-frete" [title]="'calculadoraFrete.titulo' | translate" itemprop="item">
                    <span itemprop="name">{{ 'calculadoraFrete.titulo' | translate }}</span>
                  </a>
                  <meta itemprop="position" content="8"/>
                </li>
                <li routerLink="/calculadora-importacao" routerLinkActive="active" #routerCalculadora="routerLinkActive"
                    itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
                  <a routerLink="/calculadora-importacao" [title]="'calculadoraImportacao.titulo' | translate"
                     itemprop="item">
                    <span itemprop="name">{{ 'calculadoraImportacao.titulo' | translate }}</span>
                  </a>
                  <meta itemprop="position" content="9"/>
                </li>
                <li routerLink="/exemplo-declaracao" routerLinkActive="active" #routerExemplo="routerLinkActive"
                    itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
                  <a routerLink="/exemplo-declaracao" [title]="'main.menu.exemploDeclaracao.titulo' | translate"
                     itemprop="item">
                    <span itemprop="name">{{ 'main.menu.exemploDeclaracao.titulo' | translate }}</span>
                  </a>
                  <meta itemprop="position" content="9"/>
                </li>
              </div>
            </div>
            <meta itemprop="position" content="8"/>
          </li>
          <li routerLink="/central-ajuda" routerLinkActive="active"
              itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
            <a routerLink="/central-ajuda" [title]="'main.menu.ajuda.titulo' | translate" itemprop="item"
               (click)="hide()">
              <span itemprop="name">{{ 'main.menu.ajuda.titulo' | translate }}</span>
            </a>
            <meta itemprop="position" content="10"/>
          </li>
        </ul>
        <ul class="nav navbar-nav mobile">
          <li routerLink="/" routerLinkActive="active" data-toggle="collapse" data-target="#menu-nao-autenticado"
              [routerLinkActiveOptions]="{exact: true}">
            <a routerLink="/" aria-current="page" [title]="'main.menu.inicio' | translate">
              {{ 'main.menu.inicio' | translate }}
            </a>
          </li>
          <li routerLink="/quem-somos" routerLinkActive="active" data-toggle="collapse"
              data-target="#menu-nao-autenticado">
            <a routerLink="/quem-somos" [title]="'main.menu.quem.somos' | translate">
              {{ 'main.menu.quem.somos' | translate }}
            </a>
          </li>
          <li routerLink="/redirecionamento" routerLinkActive="active" data-toggle="collapse"
              data-target="#menu-nao-autenticado">
            <a routerLink="/redirecionamento" [title]="'main.menu.redirecionamento' | translate">
              {{ 'main.menu.redirecionamento' | translate }}
            </a>
          </li>
          <li routerLink="/compra-viajabox" routerLinkActive="active" data-toggle="collapse"
              data-target="#menu-nao-autenticado">
            <a routerLink="/compra-viajabox" [title]="'main.menu.compra.viajabox' | translate">
              {{ 'main.menu.compra.viajabox' | translate }}
            </a>
          </li>
          <li routerLink="/vantagens" routerLinkActive="active" data-toggle="collapse"
              data-target="#menu-nao-autenticado">
            <a routerLink="/vantagens" [title]="'main.menu.vantagens' | translate">
              {{ 'main.menu.vantagens' | translate }}
            </a>
          </li>
          <li routerLink="/lojas-sugeridas" routerLinkActive="active" data-toggle="collapse"
              data-target="#menu-nao-autenticado">
            <a routerLink="/lojas-sugeridas" [title]="'main.menu.lojas' | translate">
              {{ 'main.menu.lojas' | translate }}
            </a>
          </li>
          <li data-toggle="collapse" data-target="#menu-nao-autenticado">
            <a href="/blog" [title]="'main.menu.blog' | translate" target="_blank">
              {{ 'main.menu.blog' | translate }}
            </a>
          </li>
          <li routerLink="/calculadora-frete" routerLinkActive="active" data-toggle="collapse"
              data-target="#menu-nao-autenticado">
            <a routerLink="/calculadora-frete" [title]="'calculadoraFrete.titulo' | translate">
              {{ 'calculadoraFrete.titulo' | translate }}
            </a>
          </li>
          <li routerLink="/calculadora-importacao" routerLinkActive="active" data-toggle="collapse"
              data-target="#menu-nao-autenticado">
            <a routerLink="/calculadora-importacao" [title]="'calculadoraImportacao.titulo' | translate">
              {{ 'calculadoraImportacao.titulo' | translate }}
            </a>
          </li>
          <li routerLink="/exemplo-declaracao" routerLinkActive="active" data-toggle="collapse"
              data-target="#menu-nao-autenticado">
            <a routerLink="/exemplo-declaracao" [title]="'main.menu.exemploDeclaracao.titulo' | translate">
              <span itemprop="name">{{ 'main.menu.exemploDeclaracao.titulo' | translate }}</span>
            </a>
          </li>
          <li routerLink="/central-ajuda" routerLinkActive="active" data-toggle="collapse"
              data-target="#menu-nao-autenticado">
            <a routerLink="/central-ajuda" [title]="'main.menu.ajuda.titulo' | translate">
              {{ 'main.menu.ajuda.titulo' | translate }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="container-fluid">
      <div class="cadastro-mobile">
        <a routerLink="/cadastro" class="btn btn-laranja" (click)="hide()"
           [title]="'main.menu.criar.conta' | translate">
          {{ 'main.menu.criar.conta' | translate }}
        </a>
        <a routerLink="/login" class="btn btn-laranja-borda" (click)="hide()"
           [title]="'main.menu.entrar' | translate">
          {{ 'main.menu.entrar' | translate }}
        </a>
      </div>
    </div>
  </nav>
</div>
