import {Component, OnDestroy} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {TokenStorageService} from "./shared/services/token-storage.service";
import {filter, firstValueFrom, Subscription, take} from "rxjs";
import {SocialAuthService} from "@abacritt/angularx-social-login";
import {GoogleTagManagerService} from "angular-google-tag-manager";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {GoogleAnalyticsService} from "ngx-google-analytics";
import {environment} from "../environments/environment";
import {Meta, Title} from "@angular/platform-browser";
import {LanguageService} from "./shared/services/language.service";
import {SwUpdate} from "@angular/service-worker";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnDestroy {

  isLoggedIn = false;
  private loggedIn: Subscription;
  private languageChange: Subscription;

  constructor(
    readonly swUpdate: SwUpdate,
    readonly title: Title,
    readonly meta: Meta,
    readonly activatedRoute: ActivatedRoute,
    readonly translateService: TranslateService,
    readonly gtmService: GoogleTagManagerService,
    readonly authService: SocialAuthService,
    readonly tokenStorageService: TokenStorageService,
    private readonly router: Router,
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    private readonly languageService: LanguageService
  ) {
    this.swUpdate.versionUpdates.subscribe(evt => this.swUpdate.activateUpdate());
    this.swUpdate.checkForUpdate();

    languageService.setLanguages(tokenStorageService.getUser()?.idioma);

    this.router.events.pipe(
      filter(item => item instanceof NavigationEnd)
    ).forEach((item: NavigationEnd) => {
      this.pageTitle(title, meta).then();
      this.canonical().then();
      this.analytics(item).then();
    }).then();

    this.languageChange = languageService.languageChange.subscribe(() => {
      this.pageTitle(title, meta).then();
      this.canonical().then();
    });

    authService.signOut(true).catch(() => null);

    this.isLoggedIn = tokenStorageService.isAuthenticated();
    this.loggedIn = this.tokenStorageService.logEvent.subscribe(logged => this.isLoggedIn = logged);
  }

  ngOnDestroy(): void {
    this.loggedIn.unsubscribe();
    this.languageChange.unsubscribe();
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  private async pageTitle(title: Title, meta: Meta): Promise<void> {
    const rt = this.getChild(this.activatedRoute);
    const data: any = await firstValueFrom(rt.data);
    await this.updateTitle(title, data.title || 'system.title');
    await this.updateDescription(meta, data.description || 'system.description');
    await this.updateKeywords(meta, data.keywords || 'system.keywords');
  }

  private async updateTitle(title: Title, titleDescription: string): Promise<void> {
    if (titleDescription) {
      let page = await firstValueFrom(this.translateService.get(titleDescription));
      title.setTitle(page);
    }
  }

  private async updateDescription(meta: Meta, description: string): Promise<void> {
    if (description) {
      let page = await firstValueFrom(this.translateService.get(description));
      this.updateMeta(meta, 'description', page);
    }
  }

  private async updateKeywords(meta: Meta, keywords: string): Promise<void> {
    if (keywords) {
      this.translateService.get(keywords).pipe(
        take(1)
      ).subscribe(page => {
        this.updateMeta(meta, 'keywords', page);
      });
    }
  }

  private updateMeta(meta: Meta, name: string, description): void {
    meta.updateTag({
      name: name,
      content: description
    });
  }

  private async canonical(): Promise<void> {
    const url = location.pathname;

    // Remove the last alternative items.
    document.querySelectorAll('link[rel=canonical]').forEach(element => element.parentNode.removeChild(element));
    document.querySelectorAll('link[rel=alternate]').forEach(element => element.parentNode.removeChild(element));

    const canonical: HTMLLinkElement = document.createElement('link');
    canonical.href = `${location.origin}${url}`;
    canonical.rel = 'canonical';
    document.head.appendChild(canonical);

    // Default language.
    this.addLinkAlternate('x-default', `${location.origin}${url}`);

    // Add available languages.
    this.languageService.availables().forEach(lang => {
      let href: string;
      href = `${location.origin}${url}?lang=${lang.code.toLowerCase()}`;
      this.addLinkAlternate(lang.code.toLowerCase(), href);
    });
  }

  private addLinkAlternate(hreflang: string, href: string): void {
    const linkLang: HTMLLinkElement = document.createElement('link');
    linkLang.rel = 'alternate';
    linkLang.hreflang = hreflang;
    linkLang.href = href;
    document.head.appendChild(linkLang);
  }

  private async analytics(item: NavigationEnd): Promise<void> {
    if (environment.production) {
      try {
        await this.gtmService.pushTag({
          event: item.url
        });

        this.googleAnalyticsService.pageView(item.url);
      } catch (e) {
        console.log("Error in analytics", e);
      }
    }
  }
}
