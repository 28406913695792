import {Component} from '@angular/core';
import {faFacebookF, faInstagram, faTwitter, faWhatsapp, faYoutube} from "@fortawesome/free-brands-svg-icons";

declare const $: any;

@Component({
  selector: 'app-topo-geral',
  templateUrl: './topo-geral.component.html',
  styleUrls: ['./topo-geral.component.scss']
})
export class TopoGeralComponent {

  protected readonly whatsapp = faWhatsapp;
  protected readonly facebook = faFacebookF;
  protected readonly instagram = faInstagram;
  protected readonly twitter = faTwitter;
  protected readonly youtube = faYoutube;

  hide(): void {
    $('#menu-nao-autenticado').collapse('hide');
    $('#ferramentas').collapse('hide');
  }

  show(id: string): void {
    $(id).collapse('show')
  }

}
