import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Globals, TokenStorageService} from "../../services/token-storage.service";
import {AlertaService} from "../../services/alerta.service";
import {Subscription, take} from "rxjs";
import {AlertaUsuarioModel} from "../../models/alerta-usuario.model";
import {UsuarioModel} from "../../models/usuario.model";
import {AutenticacaoService} from "../../services/autenticacao.service";
import {CreditoDebitoUsuarioDto} from "../../models/dto/credito-debito-usuario.dto";
import {Router} from "@angular/router";
import {TooltipDirective} from "ngx-bootstrap/tooltip";
import {
  faArchive,
  faBook,
  faBookSkull,
  faBoxesPacking,
  faCalculator,
  faCamera,
  faCartShopping,
  faClock,
  faCog,
  faCreditCard,
  faCubesStacked,
  faDollyBox,
  faDownload,
  faEnvelope,
  faFileCircleCheck,
  faFireFlameCurved,
  faGlobe,
  faHandsHelping,
  faHome,
  faInfo,
  faListCheck,
  faMailBulk,
  faPaperPlane,
  faPlane,
  faQuestionCircle,
  faRotateLeft,
  faRoute,
  faShoppingBag,
  faSignOutAlt,
  faTicket,
  faUsers
} from "@fortawesome/free-solid-svg-icons";

declare const $: any;

@Component({
  selector: 'app-topo-interno',
  templateUrl: './topo-interno.component.html',
  styleUrls: ['./topo-interno.component.scss']
})
export class TopoInternoComponent implements OnInit, OnDestroy {

  archive = faArchive;
  cartShopping = faCartShopping;
  plane = faPlane;
  handsHelping = faHandsHelping;
  cog = faCog;
  globe = faGlobe;
  download = faDownload;
  book = faBook;
  users = faUsers;
  email = faEnvelope;
  emailBulk = faMailBulk;
  boxesPacking = faBoxesPacking;
  home = faHome;
  shoppingBasket = faShoppingBag;
  info = faInfo;
  calculator = faCalculator;
  camera = faCamera;
  signOut = faSignOutAlt;
  questionCircle = faQuestionCircle;
  exemploDeclaracao = faFileCircleCheck;
  dolly = faDollyBox;
  cubes = faCubesStacked;
  checklist = faListCheck;
  creditCard = faCreditCard;
  bookSkull = faBookSkull;
  campain = faFireFlameCurved;
  coupon = faTicket;
  clock = faClock;
  route = faRoute;
  returned = faRotateLeft;
  notify = faPaperPlane;
  assisted = faHandsHelping

  globals: Globals;
  usuario: UsuarioModel;
  creditoDebitoUsuario: CreditoDebitoUsuarioDto;
  creditosASeremSacados;
  pontuacaoUsuario;
  somaItensEstoque;
  isAdmin = false;

  @ViewChild("idEstoqueEnvio", {static: true})
  idEstoqueEnvio: TooltipDirective;

  isMobileView?: boolean;

  private atualizaContagemEstoque: Subscription;
  private globalsSubscription: Subscription;

  constructor(
    private readonly router: Router,
    private readonly tokenStorageService: TokenStorageService,
    private readonly alertaService: AlertaService,
    private readonly autenticacaoService: AutenticacaoService
  ) {
    this.globalsSubscription = TokenStorageService.globalsChanged.subscribe(globals => {
      this.globals = globals;
    });

    this.usuario = tokenStorageService.getUser();
    this.isAdmin = tokenStorageService.isAdmin();
  }

  ngOnInit(): void {
    if (this.tokenStorageService.getUser().primeiroEnvio) {
      setTimeout(() => this.idEstoqueEnvio.show(), 3000);
    }

    this.atualizaContagemEstoque = this.tokenStorageService.atualizaEstoque.subscribe(() => {
      this.atualizareAlertasUsuario();
      this.atualizarPontuacao();
      this.atualizarSomaEstoque();
    });
    this.tokenStorageService.atualizaEstoque.next();
  }

  ngOnDestroy(): void {
    this.atualizaContagemEstoque.unsubscribe();
    this.globalsSubscription.unsubscribe();
  }

  private atualizareAlertasUsuario(): void {
    this.alertaService.atualizarListaAlertaPorUsuario();
    this.autenticacaoService.recuperarCreditoDebitoUsuario().pipe(
      take(1)
    ).subscribe(response => {
      this.creditoDebitoUsuario = response;
      this.creditosASeremSacados = this.creditoDebitoUsuario.valorTotalCredito - this.creditoDebitoUsuario.valorTotalDebito;
    });
  }

  private atualizarSomaEstoque(): void {
    this.autenticacaoService.recuperarUsuario().pipe(
      take(1)
    ).subscribe(response => {
      this.usuario = response.entity;
      this.somaItensEstoque = response.somaItensEstoque;
    });
  }

  private atualizarPontuacao(): void {
    this.autenticacaoService.recuperarResumoEnvioUsuario().pipe(
      take(1)
    ).subscribe(response => this.pontuacaoUsuario = response.pontuacaoUsuario);
  }

  get isAdminRoute(): boolean {
    return this.router.url.startsWith('/admin');
  }

  logout(): void {
    this.autenticacaoService.clearCredentials();
    this.router.navigate(['/login']).then();
  }

  clickMenuMobile(): void {
    this.hideMenuUsuario();
    this.isMobileView = !this.isMobileView;
    this.tokenStorageService.isMobileView = this.isMobileView;
  }

  verificarEnvios(alertaUsuario: AlertaUsuarioModel): void {
    if (!alertaUsuario.bolAlertaAppLido) {
      this.alertaService.marcarLeituraAlerta(alertaUsuario).pipe(
        take(1)
      ).subscribe(() => {
        this.alertaService.atualizarListaAlertaPorUsuario();
        this.router.navigate(['/envios/cadastrados']).then();
      });
    }
  }

  marcarLeituraAlerta(alertaUsuario: AlertaUsuarioModel): void {
    if (!alertaUsuario.bolAlertaAppLido) {
      this.alertaService.marcarLeituraAlerta(alertaUsuario).pipe(
        take(1)
      ).subscribe(() => {
        this.alertaService.atualizarListaAlertaPorUsuario();
      });
    }
  }

  verificarCompras(alertaUsuario: AlertaUsuarioModel): void {
    if (!alertaUsuario.bolAlertaAppLido) {
      this.alertaService.marcarLeituraAlerta(alertaUsuario).pipe(
        take(1)
      ).subscribe(() => {
        this.alertaService.atualizarListaAlertaPorUsuario();
        this.router.navigate(['/compra-assistida']).then()
      });
    }
  }

  verificarItensEstoque(alertaUsuario: AlertaUsuarioModel): void {
    if (!alertaUsuario.bolAlertaAppLido) {
      this.alertaService.marcarLeituraAlerta(alertaUsuario).pipe(
        take(1)
      ).subscribe(() => {
        this.alertaService.atualizarListaAlertaPorUsuario();
        this.router.navigate(['/envios/itens-estoque']).then()
      });
    }
  }

  verificarCaixaRecebida(alertaUsuario: AlertaUsuarioModel): void {
    if (!alertaUsuario.bolAlertaAppLido) {
      this.alertaService.marcarLeituraAlerta(alertaUsuario).pipe(
        take(1)
      ).subscribe(() => {
        this.alertaService.atualizarListaAlertaPorUsuario();
        this.router.navigate(['/envios/caixas-recebidas']).then()
      });
    }
  }

  onChangeFoto(usuario: UsuarioModel): void {
    this.usuario.idArquivo = usuario.idArquivo;
    const user = this.tokenStorageService.getUser();
    user.idArquivo = usuario.idArquivo;
    this.tokenStorageService.saveUser(user);
  }

  hideMenu(): void {
    $('sub_categoria_4_mobile').collapse('hide');
    this.isMobileView = false;
    this.tokenStorageService.isMobileView = false;
  }

  hideMenuUsuario(): void {
    $('#menu-usuario').removeClass('open')
  }
}
