import {Component} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {faChevronUp, faEnvelopeOpenText} from "@fortawesome/free-solid-svg-icons";
import {faFacebookF, faInstagram, faTwitter, faWhatsapp, faYoutube} from "@fortawesome/free-brands-svg-icons";
import {Utils} from "../../framework/utils";
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs";
import {VERSION} from "../../../../version";

@Component({
  selector: 'app-footer-interno',
  templateUrl: './footer-interno.component.html',
  styleUrls: ['./footer-interno.component.scss']
})
export class FooterInternoComponent {

  envelopeOpenText = faEnvelopeOpenText;
  chevronUp = faChevronUp;
  whatsapp = faWhatsapp;
  facebook = faFacebookF;
  instagram = faInstagram;
  twitter = faTwitter;
  youtube = faYoutube;

  contactEmail: string;
  readonly data = new Date();

  admin: boolean;

  constructor(
    private readonly router: Router
  ) {
    this.contactEmail = environment.contactEmail;

    this.router.events.pipe(
      filter(item => item instanceof NavigationEnd)
    ).forEach((item: NavigationEnd) => this.admin = item.url.startsWith('/admin')).then();
  }

  scrollToTop = Utils.scrollTo;
  protected readonly VERSION = VERSION + (!environment.production ? " (testing)" : "");
}
