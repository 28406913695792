import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';

@Directive({
  selector: '[noWhitespace]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: NoWhitespaceDirective,
    multi: true
  }],
  standalone: true
})
export class NoWhitespaceDirective implements Validator {

  @Input()
  wRequired: boolean = false;

  validate(control: AbstractControl): ValidationErrors {
    if (this.wRequired) {
      if (!control.value || control.value.toString().trim() == '') {
        return {'required': true};
      }
    }

    return null;
  }

}
