import {DTOModel} from "../../framework/dto.model";

export class PesquisaUsuarioDto extends DTOModel<string>{

  public pesquisa?: string;

  public parametro2?: string;

  public tipoPesquisaEnum?: string;
}
