import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService} from "../framework/base.service";
import {PedidoSaqueDto} from "../models/dto/pedido-saque.dto";
import {Observable} from "rxjs";
import {PaymentResponseDto} from "../models/dto/payment-response.dto";
import {MessageService} from "./message.service";

@Injectable({
  providedIn: 'root'
})
export class CambioRealService extends BaseService<PedidoSaqueDto, number> {

  constructor(
    readonly http: HttpClient,
    readonly messageService: MessageService
  ) {
    super('/cambioRealRestService', http, messageService);
  }

  registrarCompraAssistida(idCompra: number): Observable<PaymentResponseDto> {
    return this.http.post<PaymentResponseDto>(`${this.baseURL}/compra-assistida/${idCompra}`, {});
  };

  registrarDevolucao(idDevolucao: number): Observable<PaymentResponseDto> {
    return this.http.post<PaymentResponseDto>(`${this.baseURL}/devolucao`, idDevolucao);
  };

  registrarEnvio(idEnvio: number, valor: number): Observable<PaymentResponseDto> {
    return this.http.post<PaymentResponseDto>(`${this.baseURL}/envio/${idEnvio}?valor=${valor}`, {});
  };

  registrarCredito(valor: number): Observable<any> {
    return this.http.post<any>(`${this.baseURL}/credito/${valor}`, {});
  };
}
