import {DecimalPipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'localizedNumber',
  pure: false,
  standalone: true
})
export class LocalizedNumberPipe implements PipeTransform {

  constructor(
    private readonly translateService: TranslateService
  ) {
  }

  transform(value: any, digitsInfo?: string): any {
    const numberPipe: DecimalPipe = new DecimalPipe(this.translateService.currentLang);
    return numberPipe.transform(value, digitsInfo);
  }
}
