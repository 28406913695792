import {AfterViewInit, Directive, ElementRef, HostListener} from "@angular/core";

declare const $: any;

@Directive({
  selector: '[money]'
})
export class MoneyDirective implements AfterViewInit {

  constructor(
    private elementRef: ElementRef
  ) {
  }

  ngAfterViewInit() {
    $(this.elementRef.nativeElement).mask('000,000,000,000,000.00', {reverse: true});
  }

  @HostListener("focus")
  onKeyUp(): void {
    this.elementRef.nativeElement.select();
  }
}
