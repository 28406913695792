import {DatePipe} from '@angular/common';
import {OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from "rxjs";
import {LanguageService} from "../services/language.service";

@Pipe({
  name: 'localizedDateTime',
  pure: false,
  standalone: true
})
export class LocalizedDateTimePipe implements PipeTransform, OnDestroy {

  private mask = 'short';
  private subscription: Subscription;

  constructor(
    readonly languageService: LanguageService,
    private readonly translateService: TranslateService
  ) {
    this.updateMask();

    this.subscription = languageService.languageChange.subscribe(lang => {
      this.updateMask();
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private updateMask(): void {
    this.translateService.get('global.mascaraDataHora').subscribe(mask => this.mask = mask);
  }

  transform(value: any): any {
    const datePipe: DatePipe = new DatePipe(this.translateService.currentLang);
    return datePipe.transform(value, this.mask);
  }

}
