import {Injectable} from '@angular/core';
import {TokenStorageService} from "../services/token-storage.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class PublicRouteGuard {

  constructor(
    private readonly tokenStorageService: TokenStorageService,
    private readonly router: Router
  ) {
  }

  canActivate(): boolean {
    if (this.tokenStorageService.isAuthenticated()) {
      this.router.navigate(['/geral/inicial']).then();
    }

    return !this.tokenStorageService.isAuthenticated();
  }
}
