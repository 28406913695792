import {Injectable} from "@angular/core";
import {BaseService} from "../framework/base.service";
import {HttpClient} from "@angular/common/http";
import {MessageService} from "./message.service";
import {Observable} from "rxjs";
import {ExemploDeclaracaoDto} from "../models/dto/exemplo-declaracao.dto";
import {ExemploDeclaracaoModel} from "../models/exemplo-declaracao.model";
import {CaixaUsuarioDto} from "../models/dto/caixa-usuario.dto";
import {ExemploDeclaracaoVO} from "../models/vo/exemplo-declaracao.vo";
import {PageModel} from "../models/page.model";

@Injectable({
  providedIn: 'root'
})
export class ExemploDeclaracaoService extends BaseService<ExemploDeclaracaoDto, number> {

  constructor(
    readonly http: HttpClient,
    readonly messageService: MessageService
  ) {
    super('/exemploDeclaracao', http, messageService);
  }

  salvar(exemploDeclaracao: ExemploDeclaracaoModel, arquivo: Blob): Observable<ExemploDeclaracaoDto> {
    const formData = new FormData();
    if (arquivo) {
      formData.append('file', arquivo);
    }

    formData.append('json', this.objectToBlob(exemploDeclaracao));

    return this.http.post<CaixaUsuarioDto>(this.baseURL, formData).pipe(
      this.mapError
    );
  }

  consultarTodos(query: string, pagination: PageModel<ExemploDeclaracaoVO> = null): Observable<PageModel<ExemploDeclaracaoVO>> {
    let params = this.setPagination(pagination);

    if (query?.trim()) {
      params = params.set("query", query.trim());
    }

    return this.http.get<PageModel<ExemploDeclaracaoVO>>(this.baseURL, {
      headers: {
        'Content-Type': 'application/json'
      },
      params
    }).pipe(
      this.mapError
    );
  }

  atualizarRastreio(declaracao: ExemploDeclaracaoModel): Observable<ExemploDeclaracaoDto> {
    return this.http.post<ExemploDeclaracaoDto>(this.baseURL + "/tracking", declaracao).pipe(
      this.mapError
    );
  }
}
