import {NgModule} from '@angular/core';
import {CaixaUsuarioComponent} from "./caixa-usuario.component";
import {SharedModule} from "../../shared.module";

@NgModule({
  declarations: [
    CaixaUsuarioComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    CaixaUsuarioComponent
  ]
})
export class CaixaUsuarioModule {
}
