<div class="modal fade" [id]="modalId" role="dialog">
  <div class="modal-dialog modal-lg-6">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <div class="titulo-modal">{{ 'main.login.alterar.foto' | translate }}</div>
        <div class="close-modal">
          <button type="button" class="close" data-dismiss="modal" (click)="cancelaUploadFoto()">&times;</button>
        </div>
      </div>
      <div class="modal-body">
        <div class="btn btn-secondary" onclick="document.getElementById('fileInput').click();" tabindex="">
          <fa-icon [icon]="camera"></fa-icon>
          {{ 'cadastrarCaixas.selecionarImagem' | translate }}
          <input type="file" id="fileInput" class="hidden" (change)="fileChangeListener($event)"
                 accept="image/*" role="button" title="{{'cadastrarCaixas.selecionarImagem' | translate}}"
                 placeholder="image">
        </div>
        <div class="cropArea m-t-1" [style]="{display: myImage?.image ? 'block' : 'none'}">
          <img-cropper #cropper [image]="myImage" [settings]="cropperSettings"></img-cropper>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" data-dismiss="modal" (click)="cancelaUploadFoto()">
          {{ 'global.cancelar' | translate }}
        </button>
        <button class="btn btn-primary" (click)="uploadFoto()" [disabled]="!myImage.image || salvando"
                [class.btn-loading]="salvando">
          <app-loading-spinner *ngIf="salvando"></app-loading-spinner>
          {{ 'global.salvar' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
