import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {TokenStorageService} from "../services/token-storage.service";

@Injectable({
  providedIn: 'root'
})
export class AdminRouteGuard {

  constructor(
    private readonly tokenStorageService: TokenStorageService,
    private readonly router: Router
  ) {
  }

  canActivate(): boolean {
    if (!this.tokenStorageService.isAuthenticated()) {
      this.router.navigate(['/']).then();
    }

    return this.tokenStorageService.isAuthenticated() && this.tokenStorageService.isAdmin();
  }
}
