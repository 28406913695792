<div class="img-container">
  <img *ngIf="width && height"
       [class]="imageClass || 'img-produto-tabela'" [alt]="alt"
       [class.statusProduto]="statusProduto != null"
       [attr.ok]="statusProduto"
       [src]="url"
       [width]="width"
       [height]="height"
       loading="lazy">
  <img *ngIf="!width || !height"
       [class]="imageClass || 'img-produto-tabela'" [alt]="alt"
       [class.statusProduto]="statusProduto != null"
       [attr.ok]="statusProduto"
       [src]="url"
       loading="lazy">
  <div *ngIf="foto360" class="foto360">360º</div>
</div>
