import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService} from "../framework/base.service";
import {TokenStorageService} from "./token-storage.service";
import {EMPTY, Observable, tap} from "rxjs";
import {ItemCaixaDto} from "../models/dto/item-caixa.dto";
import {CaixaUsuarioModel} from "../models/caixa-usuario.model";
import {UsuarioModel} from "../models/usuario.model";
import {HistoricoTransferenciaItemCaixaDto} from "../models/dto/historico-transferencia-item-caixa.dto";
import {EnvioModel} from "../models/envio.model";
import {GerenciarItemDto} from "../models/dto/gerenciar-item.dto";
import {GerenciarItemVo} from "../models/vo/gerenciar-item.vo";
import {ItemCaixaModel} from "../models/item-caixa.model";
import {MessageService} from "./message.service";
import {PageModel} from "../models/page.model";

@Injectable({
  providedIn: 'root'
})
export class ItemCaixaUsuarioService extends BaseService<ItemCaixaDto, number> {

  constructor(
    readonly http: HttpClient,
    readonly messageService: MessageService,
    private readonly tokenStorageService: TokenStorageService
  ) {
    super('/itemCaixaRestService', http, messageService);
  }

  recuperarItensCaixa(usuario: UsuarioModel, caixaSelecionada: CaixaUsuarioModel): Observable<ItemCaixaDto> {
    caixaSelecionada.usuario = usuario;
    return this.http.post<ItemCaixaDto>(`${this.baseURL}/recuperarItensCaixa`, caixaSelecionada).pipe(
      this.mapError
    );
  }

  recuperarItensCaixaPorId(idItemCaixa: number): Observable<ItemCaixaDto> {
    return this.http.post<ItemCaixaDto>(`${this.baseURL}/recuperarItensCaixaPorId`, idItemCaixa).pipe(
      this.mapError
    );
  }

  recuperarHistoricoItem(idItemCaixa: number): Observable<HistoricoTransferenciaItemCaixaDto> {
    return this.http.post<HistoricoTransferenciaItemCaixaDto>(`${this.baseURL}/recuperarHistoricoItem`, idItemCaixa).pipe(
      this.mapError
    );
  }

  recuperarHistoricoItemTransferencia(idItemCaixa: number): Observable<HistoricoTransferenciaItemCaixaDto> {
    return this.http.post<HistoricoTransferenciaItemCaixaDto>(`${this.baseURL}/recuperarHistoricoItemTransferencia`, idItemCaixa).pipe(
      this.mapError
    );
  }

  recuperarItensCaixaParaEnvioPorUsuario(): Observable<ItemCaixaDto> {
    return this.http.post<ItemCaixaDto>(`${this.baseURL}/recuperarItensCaixaParaEnvioPorUsuario`, {}).pipe(
      this.mapError
    );
  }

  recuperarItensCaixaParaEnvioPorUsuarioEdicao(idUsuario: number = null, idEnvio: number = null): Observable<ItemCaixaDto> {
    let url = `${this.baseURL}/recuperarItensCaixaParaEnvioPorUsuarioEdicao`;

    if (idUsuario) {
      url += `/${idUsuario}`;
    }
    if (idEnvio) {
      url += `?idEnvio=${idEnvio}`;
    }
    return this.http.get<ItemCaixaDto>(url, {}).pipe(
      this.mapError
    );
  }

  recuperarItensGerenciarItem(item: GerenciarItemVo, page: PageModel<GerenciarItemVo>): Observable<GerenciarItemDto> {
    let params = this.setPagination(page);
    return this.http.post<GerenciarItemDto>(`${this.baseURL}/recuperarItensGerenciarItem`, item, {
      params
    }).pipe(
      this.mapError
    );
  }

  recuperarItensVencidos(suites: string, page: PageModel<GerenciarItemDto>): Observable<GerenciarItemDto> {
    let params = this.setPagination(page);

    return this.http.post<GerenciarItemDto>(`${this.baseURL}/recuperarItensVencidos`, suites, {
      params
    }).pipe(
      this.mapError
    );
  }

  recuperarItensEnviadoGerenciarItem(item: GerenciarItemVo, page: PageModel<GerenciarItemVo>): Observable<GerenciarItemDto> {
    let params = this.setPagination(page);
    return this.http.post<GerenciarItemDto>(`${this.baseURL}/recuperarItensEnviadoGerenciarItem`, item, {
      params
    }).pipe(
      this.mapError
    );
  }

  recuperarCaixasGerenciarItem(item: GerenciarItemVo, page: PageModel<GerenciarItemVo>): Observable<GerenciarItemDto> {
    let params = this.setPagination(page);
    return this.http.post<GerenciarItemDto>(`${this.baseURL}/recuperarCaixasGerenciarItem`, item, {
      params
    }).pipe(
      this.mapError
    );
  }

  recuperarSomaItensEstoque(): Observable<ItemCaixaDto> {
    if (this.tokenStorageService.isAuthenticated()) {
      return this.http.post<ItemCaixaDto>(`${this.baseURL}/recuperarSomaItensEstoque`, {}).pipe(
        tap(soma => TokenStorageService.updateSomaItemEstoque(soma.somaItensEstoque)),
        this.mapError
      );
    } else {
      TokenStorageService.updateSomaItemEstoque(0);
      return EMPTY;
    }
  }

  getImagem360Url(idItemCaixa: number): string {
    return `${this.baseURL}/${idItemCaixa}/imagem-360`;
  }

  recuperarSomaItensEstoqueMaisEnviadoPorUsuario(usuario: UsuarioModel): Observable<ItemCaixaDto> {
    if (this.tokenStorageService.isAuthenticated()) {
      return this.http.post<ItemCaixaDto>(`${this.baseURL}/recuperarSomaItensEstoqueMaisEnviadoPorUsuario`, usuario).pipe(
        this.mapError
      );
    }

    return EMPTY;
  }

  recuperarSomaItensEstoqueEnvioPorUsuario(envio: EnvioModel): Observable<ItemCaixaDto> {
    if (this.tokenStorageService.isAuthenticated()) {
      return this.http.post<ItemCaixaDto>(`${this.baseURL}/recuperarSomaItensEstoqueEnvioPorUsuario`, envio).pipe(
        this.mapError
      );
    }

    return EMPTY;
  }

  salvarItemCaixa(itemCaixa: ItemCaixaModel, arquivo: Blob): Observable<ItemCaixaDto> {
    const formData = new FormData();
    formData.append('file', arquivo);
    formData.append('json', this.objectToBlob(itemCaixa));
    return this.http.post<ItemCaixaDto>(`${this.baseURL}/salvarItemCaixa`, formData).pipe(
      this.mapError
    );
  }

  excluirItemCaixa(itemCaixa: ItemCaixaModel): Observable<ItemCaixaDto> {
    return this.http.post<ItemCaixaDto>(`${this.baseURL}/excluirItemCaixa`, itemCaixa).pipe(
      this.mapError
    );
  }

  limparItemCaixa(idItemCaixa: number): Observable<ItemCaixaDto> {
    return this.http.post<ItemCaixaDto>(`${this.baseURL}/limparItemCaixa`, idItemCaixa).pipe(
      this.mapError
    );
  }


  voltarItemCliente(gerenciarItemVo: GerenciarItemVo): Observable<ItemCaixaDto> {
    return this.http.post<ItemCaixaDto>(`${this.baseURL}/voltarItemCliente`, gerenciarItemVo).pipe(
      this.mapError
    );
  }

  transferirItemCaixa(itemCaixa: ItemCaixaModel): Observable<ItemCaixaDto> {
    return this.http.post<ItemCaixaDto>(`${this.baseURL}/transferirItemCaixa`, itemCaixa).pipe(
      this.mapError
    );
  }

  salvarProdutoRisco(itemCaixa: ItemCaixaModel): Observable<ItemCaixaDto> {
    return this.http.post<ItemCaixaDto>(`${this.baseURL}/salvarProdutoRisco`, itemCaixa).pipe(
      this.mapError
    );
  }

}
