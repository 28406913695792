import {Component, Input, OnDestroy} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Subscription, take} from "rxjs";
import {LanguageService} from "../../services/language.service";
import {ItemCaixaModel} from "../../models/item-caixa.model";

@Component({
  selector: 'app-caixa-usuario',
  templateUrl: './caixa-usuario.component.html',
  styleUrls: ['./caixa-usuario.component.scss']
})
export class CaixaUsuarioComponent implements OnDestroy {

  private languageChange: Subscription;
  caixa$: string | number;
  foto360: boolean;
  alt: string;

  @Input()
  statusProduto: boolean;

  @Input()
  imageClass: string;

  constructor(
    readonly languageService: LanguageService,
    private readonly translateService: TranslateService
  ) {
    this.languageChange = languageService.languageChange.subscribe(() => {
      this.updateAlt();
    });

    this.updateAlt();
  }

  ngOnDestroy(): void {
    this.languageChange.unsubscribe();
  }

  private updateAlt(): void {
    this.translateService.get('compraAssistida.produto.imagem').pipe(
      take(1)
    ).subscribe(t => this.alt = t);
  }

  @Input()
  set itemCaixa(caixa: ItemCaixaModel) {
    if (caixa) {
      this.caixa$ = caixa.idArquivo;
      this.foto360 = caixa.bolFoto360;
    }
  }

  @Input()
  set caixa(c: string | number) {
    if (c) {
      this.caixa$ = c;
      this.foto360 = false;
    }
  }

  get url(): string {
    if (this.caixa$) {
      let params = "";
      if (this.width && this.height) {
        // x2 for HiDPI.
        params = `?width=${this.width * 2}&height=${this.height * 2}`;
      }
      return `rest/arquivoRestService/recuperarCaixasUsuario/${this.caixa$}${params}`;
    }

    return "/assets/img/nopic.webp";
  }

  get width(): number {
    if (!this.imageClass || !this.caixa$) {
      return 134;
    }

    return null;
  }

  get height(): number {
    if (!this.imageClass || !this.caixa$) {
      return 100;
    }

    return null;
  }
}
