import {NgModule} from '@angular/core';
import {SharedModule} from "../../shared.module";
import {VisualizarExemploComponent} from "./visualizar-exemplo.component";
import {LocalizedDatePipe} from "../../pipes/localized-date.pipe";
import {LocalizedNumberPipe} from "../../pipes/localized-number.pipe";
import {CaixaUsuarioModule} from "../caixa-usuario/caixa-usuario.module";
import {LocalizedDateTimePipe} from "../../pipes/localized-date-time.pipe";
import {ImagePreviewModule} from "../image-preview/image-preview.module";

@NgModule({
  declarations: [
    VisualizarExemploComponent
  ],
  imports: [
    SharedModule,
    LocalizedDatePipe,
    LocalizedNumberPipe,
    CaixaUsuarioModule,
    LocalizedDateTimePipe,
    ImagePreviewModule
  ],
  exports: [
    VisualizarExemploComponent
  ]
})
export class VisualizarExemploModule {
}
