import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {AutenticacaoService} from "../../services/autenticacao.service";
import {MessageService} from "../../services/message.service";
import {take} from "rxjs";
import {CropperSettings, ImageCropperComponent} from 'ngx-img-cropper';
import {UsuarioModel} from "../../models/usuario.model";
import {Utils} from "../../framework/utils";
import {faCamera} from "@fortawesome/free-solid-svg-icons";

declare const $: any;

@Component({
  selector: 'app-foto-usuario',
  templateUrl: './foto-usuario.component.html'
})
export class FotoUsuarioComponent {

  camera = faCamera;

  myImage: {
    image?: any;
  };

  salvando: boolean;

  @Input()
  modalId = "modalImagemFoto";

  @Output()
  update = new EventEmitter<UsuarioModel>();

  cropperSettings: CropperSettings;

  @ViewChild('cropper', {static: true})
  cropper: ImageCropperComponent;

  constructor(
    private readonly autenticacaoService: AutenticacaoService,
    private readonly messageService: MessageService
  ) {
    this.cropperSettings = new CropperSettings({
      width: 350,
      height: 350,
      croppedWidth: 500,
      croppedHeight: 500,
      canvasWidth: 350,
      canvasHeight: 350,
      noFileInput: true,
      rounded: true,
      keepAspect: true,
      cropperClass: 'cropArea',
      fileType: 'image/png'
    });
    this.myImage = {};
  }

  uploadFoto(): void {
    this.salvando = true;
    this.autenticacaoService.uploadFoto(this.myImage.image).pipe(
      take(1)
    ).subscribe({
      next: response => {
        this.messageService.addSuccess('global.salvarSucesso');
        this.myImage = {};
        $('#modalImagemFoto').modal('hide');
        this.update.emit(response.entity);
      },
      complete: () => this.salvando = false
    });
  }

  fileChangeListener($event): void {
    let file: File = $event.target.files[0];
    Utils.blobToDataURL(file, ret => {
      let image: any = new Image();
      image.src = ret;
      setTimeout(() => this.cropper.setImage(image), 100);
    });
  }

  cancelaUploadFoto(): void {
    this.myImage = {};
  }
}
