import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {AlertaUsuarioModel} from "../models/alerta-usuario.model";
import {UsuarioDto} from "../models/dto/usuario.dto";
import {UsuarioModel} from "../models/usuario.model";

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const USUARIO_INDICACAO_KEY = 'usuarioIndicacao';

export class Globals {
  somaAlertaCaixa?: number;
  somaAlertaCompras?: number;
  somaAlertaEnvio?: number;
  somaAlertaEstoque?: number;
  somaItensEstoque?: number;
  listaAlertaUsuarioCaixa?: AlertaUsuarioModel[];
  listaAlertaEstoque?: AlertaUsuarioModel[];
  listaAlertaEnvio?: AlertaUsuarioModel[];
  listaAlertaCompras?: AlertaUsuarioModel[];
}

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  private static $globals: Globals = null;
  static readonly globalsChanged = new Subject<Globals>();

  private static $isMobileView = false;
  static readonly isMobileViewChanged = new Subject<boolean>();

  logEvent = new Subject<boolean>();
  atualizaEstoque = new Subject<void>();

  get usuarioIndicacao(): number {
    return Number(sessionStorage.getItem(USUARIO_INDICACAO_KEY)) || null;
  }

  set usuarioIndicacao(usuario: number) {
    sessionStorage.setItem(USUARIO_INDICACAO_KEY, usuario ? usuario.toString() : null);
  }

  get isMobileView(): boolean {
    return TokenStorageService.$isMobileView;
  }

  set isMobileView(view: boolean) {
    TokenStorageService.$isMobileView = view;
    TokenStorageService.isMobileViewChanged.next(view);
  }

  static setGlobals(usuario: UsuarioDto): void {
    let somaItensEstoque = usuario.somaItensEstoque;
    let listaAlertaUsuarioCaixa = usuario.listaAlertaUsuarioCaixa;
    let listaAlertaEstoque = usuario.listaAlertaEstoque;
    let listaAlertaEnvio = usuario.listaAlertaEnvio;
    let listaAlertaCompras = usuario.listaAlertaCompras;

    this.$globals = {
      somaItensEstoque: somaItensEstoque || 0,
      listaAlertaUsuarioCaixa: listaAlertaUsuarioCaixa,
      somaAlertaCaixa: listaAlertaUsuarioCaixa ? listaAlertaUsuarioCaixa.length : 0,
      listaAlertaEstoque: listaAlertaEstoque,
      somaAlertaEstoque: listaAlertaEstoque ? listaAlertaEstoque.length : 0,
      listaAlertaEnvio: listaAlertaEnvio,
      somaAlertaEnvio: listaAlertaEnvio ? listaAlertaEnvio.length : 0,
      listaAlertaCompras: listaAlertaCompras,
      somaAlertaCompras: listaAlertaCompras ? listaAlertaCompras.length : 0
    }

    // Notifica o sistema da alteração.
    this.globalsChanged.next(this.$globals);
  }

  public isAuthenticated(): boolean {
    return this.getToken() != null;
  }

  signOut(): void {
    TokenStorageService.$globals = null;
    sessionStorage.clear();
    this.logEvent.next(false);
  }

  public saveToken(token: string): void {
    sessionStorage.removeItem(TOKEN_KEY);
    sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    return sessionStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user: UsuarioModel): void {
    sessionStorage.removeItem(USER_KEY);
    sessionStorage.setItem(USER_KEY, JSON.stringify(user));
    this.logEvent.next(true);
  }

  public getUser(): UsuarioModel {
    return JSON.parse(sessionStorage.getItem(USER_KEY));
  }

  isAdminPrsg(): boolean {
    return this.getUser().perfil == 'ADMINISTRADOR_GERAL_PRSG';
  }

  isAdminPrs(): boolean {
    return this.getUser().perfil == 'ADMINISTRADOR_PRS';
  }

  isAdmin(): boolean {
    return this.isAdminPrs() || this.isAdminPrsg();
  }

  static updateSomaItemEstoque(somaItensEstoque: number) {
    this.$globals.somaItensEstoque = somaItensEstoque;

    // Notifica o sistema da alteração.
    this.globalsChanged.next(this.$globals);
  }
}
