<div>
  <div class="login conteudo nao-autenticado">
    <div class="titulo-pagina">
      <div class="container-fluid">
        <h1>{{'main.login.entrar.titulo' | translate}}</h1>
      </div>
    </div>

    <div class="corpo-nao-autenticado">
      <div class="container-fluid">
        <div class="col-lg-6 form-login center">
          <form name="formLogin" id="formLogin" (submit)="autenticarUsuario()" role="form" aria-labelledby="titleSenha">
            <div class="col-lg-6 form-esq">
              <h2 class="subtitulo text-left" id="titleSenha">{{'main.login.titulo' | translate}}</h2>
              <fieldset class="form-group" aria-labelledby="title">
                <legend hidden="hidden">{{'main.login.campoLogin' | translate}}</legend>
                <div class="group-item">
                  <label for="inputLoginPrincipal">
                    <fa-icon [icon]="user"></fa-icon>
                  </label>
                  <input id="inputLoginPrincipal" class="form-control" type="text" focus
                         autocomplete="username" enterkeyhint="send"
                         (keyup)="retirarCaracteresEspeciais()" [(ngModel)]="usuario.login" name="inputLoginPrincipal"
                         maxlength="100" [placeholder]="'main.login.campoLogin' | translate"
                         wRequired="true" noWhitespace>
                </div>
              </fieldset>
              <fieldset class="form-group" aria-labelledby="title">
                <legend hidden="hidden">{{'main.login.campoSenha' | translate}}</legend>
                <div class="group-item">
                  <label for="inputSenhaLoginPrincipal">
                    <fa-icon [icon]="lock"></fa-icon>
                  </label>
                  <input id="inputSenhaLoginPrincipal" class="form-control" type="password"
                         autocomplete="current-password" enterkeyhint="send"
                         [(ngModel)]="usuario.senha" maxlength="50" name="inputSenhaLoginPrincipal"
                         [placeholder]="'main.login.campoSenha' | translate" wRequired="true" noWhitespace/>
                </div>
                <div class="link-esqueceu-senha">
                  <div class="text-left">
                    <a href="" data-toggle="modal" data-target="#modalEsqueceuSenha" class="link-esqueci-senha"
                       [title]="'main.login.esqueceuSenha' | translate">
                      {{'main.login.esqueceuSenha' | translate}}
                    </a>
                  </div>
                </div>
              </fieldset>

              <div class="col-lg-12">
                <div class="row">
                  <button type="submit" class="btn btn-laranja" [disabled]="loading">
                    <app-loading-spinner *ngIf="loading"></app-loading-spinner>
                    {{'main.login.entrar.titulo' | translate}}
                  </button>
                </div>
              </div>
            </div>
            <div class="texto-login-ou">{{'main.login.ou' | translate}}</div>
            <div class="col-lg-6 form-dir" id="fb-root">
              <div id="customBtnLogin" class="group-btn-google">
                <asl-google-signin-button
                  type="standard"
                  size="large"
                  shape="rectangular"
                  logo_alignment="left"
                ></asl-google-signin-button>
              </div>
              <div class="group-btn-face">
                <button type="button" class="btn btn-login-face" (click)="signInWithFB()">
                  <fa-icon [icon]="facebook" class="logofb"></fa-icon>
                  {{'main.login.entrar.face' | translate}}
                </button>
              </div>
            </div>
            <div class="col-lg-12 form-baixo">
              <h3 class="chamada">{{'main.login.semConta' | translate}}</h3>
              <h4 class="chamada">{{'main.login.cadastrese.gratis' | translate}}</h4>
              <button routerLink="/cadastro" class="btn btn-laranja-borda" type="button">
                {{'main.login.cadastrese.titulo' | translate}}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal Esqueceu Senha-->
<div class="modal fade" id="modalEsqueceuSenha" role="dialog">
  <div class="modal-dialog modal-lg-6">
    <!-- Modal content-->
    <div class="modal-content">
      <form name="formRecuperarSenha" role="form" aria-labelledby="tituloEsqueceuSenha" #formSenha="ngForm">
        <div class="modal-header">
          <div class="titulo-modal">{{'main.login.esqueceuSenha' | translate}}</div>
          <div class="close-modal">
            <button type="button" class="close" data-dismiss="modal" [disabled]="bloquearBotaoSenha">&times;</button>
          </div>
        </div>
        <div class="modal-body">
          <label for="inputEmail" class="enviolabel">
            {{'main.login.email' | translate}}:
          </label>
          <input id="inputEmail" type="email" maxlength="100" class="col-xs-8 col-lg-10 col-sm-8 col-md-8 form-control"
                 [(ngModel)]="emailSenha" [placeholder]="'main.login.email' | translate"
                 (keyup)="retirarCaracteresEspeciais()" wRequired="true" name="emailSenha" noWhitespace>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            {{'global.fechar' | translate}}
          </button>
          <button type="button" class="btn btn-primary" (click)="recuperarSenha()"
                  [disabled]="bloquearBotaoSenha || formSenha.invalid">
            {{'main.login.recuperarSenha' | translate}}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Modal Email não confirmado-->
<div class="modal fade" id="modalEmailNaoConfirmado" role="dialog">
  <div class="modal-dialog modal-lg-6">
    <!-- Modal content-->
    <div class="modal-content">
        <div class="modal-header">
          <div class="titulo-modal">{{'main.login.emailsNaoConfirmado' | translate}}</div>
        </div>
        <div class="modal-body">
          <label for="inputEmail" class="enviolabel">
            {{'BO_USUARIO_NAO_CONFIRMADO' | translate}} {{emailNaoConfirmado}}
          </label>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            {{'global.fechar' | translate}}
          </button>
          <button type="button" class="btn btn-primary" (click)="reenviarEmailNaoConfirmado()">
             {{'main.login.reenvioEmailCadastro' | translate}}
          </button>
        </div>
    </div>
  </div>
</div>
