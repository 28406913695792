<app-loading [loading]="loading"></app-loading>
<div *ngIf="!loading">
  <form name="formCadastrarItens" role="form">
    <div class="col-md-4 painel text-left">
      <label for="dtInclusao">
        {{ 'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.dataPagamento' | translate }}:&nbsp;
      </label>
      <span id="dtInclusao">{{ envio?.listaEnvioPagamento?.[0]?.dtInclusao | localizedDate | emptyValue }}</span>
      <br>

      <label for="prazoEntrega">
        {{ 'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.prazoEntrega' | translate }}:
      </label>
      <span id="prazoEntrega">
        {{ exemplo$.prazo | localizedNumber }}
        {{ 'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.dias' | translate }}
      </span>
      <br>

      <label for="valorDeclarado">
        {{ 'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.valorDeclarado' | translate }}:&nbsp;
      </label>
      <span id="valorDeclarado">
        <app-country-flags country="US" class="p-l-0" *ngIf="exemplo$.valorDeclarado"></app-country-flags>
        {{ exemplo$.valorDeclarado | moeda | emptyValue }}
      </span>
      <br>

      <label for="valorSeguro">
        {{ 'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.valorSeguro' | translate }}:&nbsp;
      </label>
      <span id="valorSeguro">
        <app-country-flags country="US" class="p-l-0" *ngIf="exemplo$.seguro"></app-country-flags>
        {{ exemplo$.seguro | moeda | emptyValue }}
      </span>
      <br>

      <label for="valorFrete">
        {{ 'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.valorFrete' | translate }}:&nbsp;
      </label>
      <span id="valorFrete">
        <app-country-flags country="US" class="p-l-0" *ngIf="exemplo$.frete"></app-country-flags>
        {{ exemplo$.frete | moeda | emptyValue }}
      </span>
      <br>

      <label for="impostoPago">
        {{ 'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.impostoPago' | translate }}:&nbsp;
      </label>
      <span id="impostoPago">
        <app-country-flags country="BR" class="p-l-0" *ngIf="exemplo$.imposto"></app-country-flags>
        <ng-container *ngIf="exemplo$.imposto"> BRL</ng-container>
        {{ exemplo$.imposto | moeda: false | emptyValue }}
      </span>
      <br>

      <label for="cidade">
        <div id="cidade">{{ exemplo$.cidade | emptyValue }}</div>
      </label>
      <br>

      <div class="col-produtos-pagamento">
        <div class="card panel-body card-produto-pagamento" *ngFor="let lista of envio.listaEnvioItemCaixa">
          <div class="foto-produto">
            <a data-toggle="modal" data-target="#myModal" (click)="selecionarVisualizarImagem(lista)">
              <app-caixa-usuario
                class="desktopm"
                [itemCaixa]="lista.itemCaixa"
              ></app-caixa-usuario>

              <app-caixa-usuario
                class="mobile"
                imageClass="image-preview"
                [itemCaixa]="lista.itemCaixa"
              ></app-caixa-usuario>
            </a>
          </div>
          <div class="detalhes-produto">
            <div class="quantidade-produto">
              <label for="numQuantidade">
                <div id="numQuantidade">{{ lista.numQuantidade }} {{ 'envios.pagamento.unidade' | translate }}</div>
              </label>
            </div>
            <label for="pagamentoPeso">{{ 'envios.pagamento.peso' | translate }}</label><br/>
            <span id="pagamentoPeso">
              {{ lista.itemCaixa.numPesoLbs | localizedNumber }} {{ 'system.lbs' | translate }},
              {{ lista.itemCaixa.numPesoOz | moeda: false }} {{ 'system.oz' | translate }}
            </span>
            <label class="descricao" *ngIf="lista.itemCaixa.descricao" for="descricao">
              {{ 'cadastrarCaixas.descricao' | translate }}
            </label>
            <span id="descricao">
              {{ lista.itemCaixa.descricao }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 painel text-left">
      <div class="row col-md-12 text-center">
        <img src="rest/arquivoRestService/recuperarCaixasUsuario/{{exemplo$.idArquivoDeclaracao}}"
             [alt]="'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.imagemDeclaracao' | translate"
             *ngIf="exemplo$.idArquivoDeclaracao">

        <img src="assets/img/nao-tributada.png"
             [alt]="'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.imagemDeclaracao' | translate"
             *ngIf="!exemplo$.idArquivoDeclaracao">
      </div>

      <label for="rastreioPacote">
        {{ 'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.rastreioPacote' | translate }}
      </label>
      <div *ngIf="exemplo$.listaRastreio?.length == 0">
        {{ 'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.rastreioIndisponivel' | translate }}
      </div>
      <div class="headertable" *ngIf="exemplo$.listaRastreio?.length > 1" id="rastreioPacote">
        <table class="table table-striped table-hover table-condensed tabela-itens responsive"
               aria-labelledby="title">
          <thead>
          <tr>
            <th scope="col">
              {{ 'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.localEvento' | translate }}
            </th>
            <th scope="col">
              {{ 'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.cidade' | translate }}
            </th>
            <th scope="col">
              {{ 'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.descricao' | translate }}
            </th>
            <th scope="col">
              {{ 'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.dataEvento' | translate }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of exemplo$.listaRastreio">
            <td
              [attr.data-title]="'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.localEvento' | translate">
              {{ item.tipo | emptyValue }}
            </td>
            <td [attr.data-title]="'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.cidade' | translate">
              <div *ngIf="!item.cidade">
                {{ null | emptyValue }}
              </div>
              <div *ngIf="item.cidade">
                {{ item.cidade | emptyValue }} ({{ item.uf | emptyValue }})
              </div>
            </td>
            <td [attr.data-title]="'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.descricao' | translate">
              {{ item.descricao| emptyValue }}
            </td>
            <td
              [attr.data-title]="'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.dataEvento' | translate">
              {{ item.dataEvento | localizedDateTime | emptyValue }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-4 painel text-left">
      <div class="col-final-pagamento">
        <div class="dec-alfandega col-xs-12 col-md-12">
          <label for="declaracaoAlfandega"> {{ 'itensEstoque.declaracaoAlfandega' | translate }}* </label>
          <br/>
          <div id="declaracaoAlfandega">
            <div class="declaracao-container" *ngFor="let d of envio.listaEnvioDeclaracaoAlfandega">
              <div class="desc-alfandega">
                <app-descricao-declaracao-envio [declaracao]="d"></app-descricao-declaracao-envio>
              </div>
              <label class="label-qtd" for="quantidadeAlfandega">{{ 'itensEstoque.qtd' | translate }}:&nbsp;</label>
              <span id="quantidadeAlfandega">{{ d.numQuantidade | emptyValue }}</span>
              <br/>
              <label class="label-valor" for="seguro">{{ 'itensEstoque.valor' | translate }}:&nbsp;</label>
              <span id="seguro">
                <app-country-flags country="US" class="p-l-0" *ngIf="exemplo$.seguro"></app-country-flags>
                {{ d.numValor | moeda: false | emptyValue }}
              </span>
              <br/>
              <label class="label-valor" *ngIf="!isMobileView" for="valorTotalLinha">
                {{ 'itensEstoque.valorTotalLinha' | translate }}:&nbsp;
              </label>
              <span *ngIf="!isMobileView" id="valorTotalLinha">
                <app-country-flags country="US" class="p-l-0" *ngIf="exemplo$.seguro"></app-country-flags>
                {{ somaValorDeclaracaoLinha(d) | moeda: false | emptyValue }}
              </span>
              <br/>
            </div>
          </div>
        </div>

      </div>
    </div>
  </form>
</div>

<!-- Modal -->
<div class="modal fade modal-itens-confirmar-envio" id="myModal" role="dialog">
  <div class="modal-dialog modal-lg">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <div class="titulo-modal" id="tituloCaixa">{{ 'envios.visualizar.envio' | translate }}</div>
        <div class="close-modal">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
      </div>
      <div class="modal-body text-center">
        <div class="acoes-modal-itens m-t-1">
          <div class="col-btn-foto-anterior">
            <button class="btn btn-primary btn-foto-anterior" [disabled]="indexItemCaixa == 0"
                    (click)="changeItemCaixa(-1)">
              <fa-icon [icon]="arrowLeft"></fa-icon>
            </button>
          </div>
          <div>{{ indexItemCaixa + 1 }}/{{ listaItemCaixa.length }}</div>
          <div class="col-btn-foto-proximo">
            <button class="btn btn-primary btn-foto-proximo"
                    [disabled]="indexItemCaixa == listaItemCaixa.length - 1" (click)="changeItemCaixa(1)">
              <fa-icon [icon]="arrowRight"></fa-icon>
            </button>
          </div>
        </div>

        <app-image-preview
          class="desktopm"
          *ngIf="envioItemCaixaSelecionado?.itemCaixa"
          [itemCaixa]="envioItemCaixaSelecionado?.itemCaixa"
        ></app-image-preview>

        <app-image-preview
          class="mobile"
          *ngIf="envioItemCaixaSelecionado?.itemCaixa"
          [itemCaixa]="envioItemCaixaSelecionado?.itemCaixa"
          [mobile]="true"
        ></app-image-preview>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ 'global.fechar' |  translate }}
        </button>
      </div>
    </div>
  </div>
</div>
