import {Component, Input, OnDestroy} from '@angular/core';
import {Subscription} from "rxjs";
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {ExemploDeclaracaoVO} from "../../models/vo/exemplo-declaracao.vo";
import {TokenStorageService} from "../../services/token-storage.service";
import {EnvioItemCaixaModel} from "../../models/envio-item-caixa.model";
import {EnvioDeclaracaoAlfandegaModel} from "../../models/envio-declaracao-alfandega.model";
import {EnvioModel} from "../../models/envio.model";

@Component({
  selector: 'app-visualizar-exemplo',
  templateUrl: './visualizar-exemplo.component.html',
  styleUrls: ['./visualizar-exemplo.component.scss']
})
export class VisualizarExemploComponent implements OnDestroy {

  arrowLeft = faArrowLeft;
  arrowRight = faArrowRight;

  loading = false;
  indexItemCaixa: number;
  isMobileView;
  envio: EnvioModel;
  envioItemCaixaSelecionado: EnvioItemCaixaModel;
  listaItemCaixa: EnvioItemCaixaModel[] = [];
  mobileChanged: Subscription;

  protected exemplo$: ExemploDeclaracaoVO;

  @Input()
  set exemplo(exemplo: ExemploDeclaracaoVO) {
    this.exemplo$ = exemplo;
    if (exemplo?.idEnvio) {
      this.envio = exemplo.envio;
      this.listaItemCaixa = this.envio.listaEnvioItemCaixa;

      this.montarListaEnvioDeclaracaoAlfandega();

      if (this.envio.valorTotalSeguroDeclaracao > 0) {
        this.envio.bolSeguro = true;
      }

      this.indexItemCaixa = 0;
      this.envio.envioProblema = {};
    }
  }

  constructor(
    readonly tokenStorageService: TokenStorageService
  ) {
    this.mobileChanged = TokenStorageService.isMobileViewChanged.subscribe(view => this.isMobileView = view);
    this.isMobileView = tokenStorageService.isMobileView;
  }

  ngOnDestroy(): void {
    this.mobileChanged.unsubscribe();
  }

  selecionarVisualizarImagem(envioItemCaixa: EnvioItemCaixaModel): void {
    this.envioItemCaixaSelecionado = envioItemCaixa;
    this.indexItemCaixa = this.listaItemCaixa.findIndex(x => x.idEnvioItemCaixa === envioItemCaixa.idEnvioItemCaixa);
  }

  montarListaEnvioDeclaracaoAlfandega(): void {
    let listaItemCaixa = this.listaItemCaixa;
    let listaEnvioDeclaracaoAlfandega = [];

    if (listaItemCaixa) {
      for (let i = 0, len = 1; i < len; i++) {
        if (i == 0) {
          listaEnvioDeclaracaoAlfandega[0] = {};
          listaEnvioDeclaracaoAlfandega[0].primeiro = true;
        }
      }

      for (let i = 0, len = this.envio.listaEnvioDeclaracaoAlfandega.length; i < len; i++) {
        if (this.envio.listaEnvioDeclaracaoAlfandega[i] != undefined && this.envio.listaEnvioDeclaracaoAlfandega[i] != null) {
          listaEnvioDeclaracaoAlfandega[i] = this.envio.listaEnvioDeclaracaoAlfandega[i];
          listaEnvioDeclaracaoAlfandega[i].primeiro = i == 0;
        }
      }

      this.envio.listaEnvioDeclaracaoAlfandega = listaEnvioDeclaracaoAlfandega;
    }
  }


  somaValorDeclaracaoLinha(envioDeclaracaoAlfandega: EnvioDeclaracaoAlfandegaModel): number {
    let calculoItem = envioDeclaracaoAlfandega.numQuantidade * Number(envioDeclaracaoAlfandega.numValor);
    if (!isNaN(calculoItem)) {
      return calculoItem;
    }

    return null;
  }

  changeItemCaixa(i: number): void {
    let indexItemCaixa = this.indexItemCaixa + (i);
    if (indexItemCaixa < 0) {
      indexItemCaixa = 0;
    } else if (indexItemCaixa > (this.listaItemCaixa.length - 1)) {
      indexItemCaixa = this.listaItemCaixa.length - 1;
    }

    this.indexItemCaixa = indexItemCaixa;
    this.envioItemCaixaSelecionado = this.listaItemCaixa[indexItemCaixa];
  }
}
