<div class="modal modal-creditos fade" [id]="modalId" role="dialog">
  <div class="modal-dialog modal-lg-6">
    <!-- Modal content-->
    <div class="modal-content">
      <form name="formCreditos" role="form">
        <div class="modal-header">
          <div class="titulo-modal">{{'inicio.modal.creditos.titulo' | translate}}</div>
          <div class="close-modal">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
          </div>

        </div>
        <div class="modal-body">
          <div>
            <div class="text-center">
              <div class="col-lg-12">
                <p class="chamada">{{'inicio.modal.creditos.texto1' | translate}}</p>
                <p class="texto1">{{'inicio.modal.creditos.texto2' | translate}}</p>
                <p class="texto1">{{'inicio.modal.creditos.texto3' | translate}}</p>
                <p class="texto1"><strong>*{{'inicio.modal.creditos.texto4' | translate}}</strong></p>
                <br/>
                <p>{{'itensEstoque.valorTotal' | translate}}</p>
                <div class="group-item valor">
                  <label for="valorCredito">US$</label>
                  <input type="text" [(ngModel)]="valorCredito" class="form-control" money inputmode="decimal"
                         id="valorCredito" name="valorCredito" focus min="0.01" step="0.01" autocomplete="off"
                         enterkeyhint="send">
                </div>
                <p *ngIf="!!valorCredito" class="m-t-1">
                  <input type="image" src="/assets/img/cambio-real.webp" alt="Pague com CambioReal"
                         width="110" (click)="registrarCredito()" enterkeyhint="send">
                </p>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
